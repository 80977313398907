import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {useSelector} from "react-redux"
import axios from "axios";
import { SERVER_PATH, SERVER_IMG_PATH } from "../AppConstants";
import InspiringLeaders from "../assets/images/inspiring-leaders-biography.png";
import '../assets/css/style.css';
import '../assets/css/biography.css';

const CategoryContant = () => {
    const [allpost, setAllpost] = useState([]);
    const [catid, setCatid] = useState();
    const [visibleItems, setVisibleItems] = useState(16);
    const [hasMore, setHasMore] = useState(true);
    const { catsurl } = useParams();
    const originalcatname = reverseTransformation(catsurl);
    //console.log(catsurl);
    const cats = useSelector((state)=> state.catsInfo.cats);

    function reverseTransformation(transformedString) {
        if (!transformedString || typeof transformedString !== 'string') {
          // Handle the case where the input is undefined, null, or not a string
          console.error("Invalid input: The value provided is not a string.");
          return "";
        }
      
        const withSpaces = transformedString.replace(/-/g, ' ');
      
        const originalString = withSpaces
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      
        return originalString;
    }
    
    useEffect(() => {
        Object.keys(cats).forEach((key) => {
          if (cats[key][1] === originalcatname) {
            setCatid(cats[key][0]);
          }
        });
    }, [cats, setCatid, originalcatname]);
    
    useEffect(() => {
        const limit = 'all';
        const fetchData = async () => {
          try {
            const res = await axios.get(`${SERVER_PATH}/catposts/${catid}/${limit}`);
            if (res.data) {
                //console.log(res.data);
                setAllpost(res.data); // Set the post data if found
              } else {
                console.error("Post not found");
                // Handle case where post is not found, like navigating to a "Not Found" page
              }
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
    }, [catid]);
    
    // Handle loading more items
    const loadMoreItems = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 12);

        // Check if there are more items to load
        if (visibleItems >= allpost.length) {
        setHasMore(false); // No more items to load
        }
    };

    useEffect(() => {
            // Base64 image string
            const base64Image = InspiringLeaders;
            const pageTitle = originalcatname + " - Biography of Famous Celebrity and Trending Personality";
            const description = "Inspiring Leaders is one-stop destination for the biography of Celebrity and Trending Personality";
    
            // Set the page title
            document.title = pageTitle;
            // Create meta tags
            const metaImage = document.createElement('meta');
            metaImage.setAttribute('property', 'og:image');
            metaImage.setAttribute('content', base64Image);
            
            const metaWidth = document.createElement('meta');
            metaWidth.setAttribute('property', 'og:image:width');
            metaWidth.setAttribute('content', '1200');
            
            const metaHeight = document.createElement('meta');
            metaHeight.setAttribute('property', 'og:image:height');
            metaHeight.setAttribute('content', '630');
    
            // Add the description meta tag
            const metaDescription = document.createElement('meta');
            metaDescription.setAttribute('name', 'description');
            metaDescription.setAttribute('content', description);   
            
            // Append meta tags to the head
            document.head.appendChild(metaImage);
            document.head.appendChild(metaWidth);
            document.head.appendChild(metaHeight);
            document.head.appendChild(metaDescription);
    
            // Cleanup function to remove meta tags on component unmount
            return () => {
            document.head.removeChild(metaImage);
            document.head.removeChild(metaWidth);
            document.head.removeChild(metaHeight);
            document.head.removeChild(metaDescription); // Remove the description meta tag
            };
    },[originalcatname]);
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, [catid]); // Empty dependency array means this runs once on mount
    //console.log(allpost);
    return (
        <div class="site-wrap">
            <header class="archive-header category-header-1 is-pattern pattern-dot">
                <div class="rb-container edge-padding">
                    <div class="archive-inner">
                        <div class="archive-header-content">
                            <aside class="breadcrumb-wrap breadcrumb-navxt archive-breadcrumb">
                                <div class="breadcrumb-inner" vocab="https://schema.org/" typeof="BreadcrumbList"> 
                                    <span property="itemListElement" typeof="ListItem">
                                        <Link property="item" typeof="WebPage" title="Go to Inspiring Leaders - Biography of Famous Celebrity and Trending Personality."  to="/" class="home">
                                            <span property="name">Inspiring Leaders - Biography of Famous Celebrity and Trending Personality</span>
                                        </Link>
                                        <meta property="position" content="1" />
                                    </span> &gt; 
                                    <span property="itemListElement" typeof="ListItem">
                                        <span property="name" class="archive taxonomy category current-item">{originalcatname}</span>
                                        <meta property="url" content={'/' +  catsurl} />
                                        <meta property="position" content="2" />
                                    </span>
                                </div>
                            </aside>
                            <div class="archive-title b-follow">
                                <h1>{originalcatname}</h1> 
                            </div>
                            {/* <div class="subcat-wrap"> 
                                <span class="subcat-heading">
                                    <i class="rbi rbi-share"></i>Find More:
                                </span> 
                                <span class="sub-cat-item h5">
                                    <a href="../category/celebrity-biography/famous-celebrity.html">Famous Celebrity</a>
                                </span> 
                                <span class="sub-cat-item h5">
                                    <a href="../category/celebrity-biography/movies-celebrity.html">Movies Celebrity</a>
                                </span> 
                                <span class="sub-cat-item h5">
                                    <a href="../category/celebrity-biography/politics-celebrity.html">Politics Celebrity</a>
                                </span> 
                                <span class="sub-cat-item h5">
                                    <a href="../category/celebrity-biography/singers-celebrity.html">Singers Celebrity</a>
                                </span> 
                                <span class="sub-cat-item h5">
                                    <a href="../category/celebrity-biography/sports-celebrity.html">Sports Celebrity</a>
                                </span> 
                            </div> */}
                        </div>
                    </div>
                </div>
            </header>
            <div class="blog-builder">
                <div data-elementor-type="wp-post" data-elementor-id="50414" class="elementor elementor-50414">
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-8ab9c3d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8ab9c3d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shape_divider_top&quot;:&quot;waves&quot;,&quot;shape_divider_bottom&quot;:&quot;waves&quot;}">
                        <div class="elementor-container elementor-column-gap-custom">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a8eb33f" data-id="a8eb33f" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-52a396e elementor-widget elementor-widget-foxiz-heading" data-id="52a396e" data-element_type="widget" data-widget_type="foxiz-heading.default">
                                        <div class="elementor-widget-container">
                                            <div id="uid_52a396e" class="block-h heading-layout-19">
                                                <div class="heading-inner">
                                                    <h2 class="heading-title"><span>{'Latest ' + originalcatname}</span></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-a8abb6a elementor-widget elementor-widget-foxiz-grid-box-1" data-id="a8abb6a" data-element_type="widget" data-widget_type="foxiz-grid-box-1.default">
                                        <div class="elementor-widget-container">
                                            <div id="uid_a8abb6a" class="block-wrap block-grid block-grid-box-1 rb-columns rb-col-4 rb-tcol-2 rb-mcol-1 is-gap-custom ecat-bg-4 is-m-list meta-s-">
                                                {topPost(allpost, 0, visibleItems, catid, originalcatname)}
                                                <div class="pagination-wrap pagination-loadmore"> 
                                                {hasMore && (
                                                    <Link onClick={loadMoreItems} disabled={!hasMore}>
                                                        <span>
                                                            {hasMore ? "Show More" : "No More Items"}
                                                        </span>
                                                        <i class="rb-loader" aria-hidden="true"></i>
                                                    </Link>
                                                )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

function topPost(postsval, start, end, catId, category_name) {
    const selectedtopPosts = postsval.slice(start, end);
    return (
        <div className="block-inner">
            {postsval ? (
                selectedtopPosts.map((postval, index) => (
                <div className="p-wrap p-grid p-box p-grid-box-1 box-shadow" data-pid="53735">
                    <div className="grid-box">
                        <div className="feat-holder overlay-text">
                            <div className="p-featured"> 
                                {featuredImg(postval, catId, category_name)} 
                            </div>
                            <div className="p-categories p-top">
                                {catlink(postval, catId, category_name)}
                            </div>
                        </div>
                        <h4 className="entry-title"> 
                            {biolink(postval, catId, category_name)}
                        </h4>
                        <div className="p-meta">
                            <div className="meta-inner is-meta"> 
                            <p class="gh-card-excerpt is-body">{postval.content}</p>
                                {/* <a className="meta-el meta-avatar">
                                <img loading="lazy" decoding="async" src={Profileimg} width="44" height="44" alt="Admin" className="avatar avatar-44 wp-user-avatar wp-user-avatar-44 alignnone photo" />
                            </a> 
                            <span className="meta-el meta-author"> 
                                <a >Admin</a> 
                            </span>  */}
                        </div>
                        </div>
                    </div>
                </div>
                ))
            ) : (
                <div class="site-wrap"> 
                    <div class="page404-wrap rb-container edge-padding">
                        <div class="page404-inner">
                            <div class="page404-featured"></div>
                            <h1 class="page-title">Something's wrong here...</h1>
                            <p class="page404-description">It looks like nothing was found at this location. The page you were looking for does not exist or was loading incorrectly.</p>
                            <div class="page404-btn-wrap">
                                <Link to="/" class="page404-btn is-btn">Return to Homepage</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
  }


function featuredImg(posts, catId, category_name) {
    const catnameurl = category_name.toLowerCase().replace(/\s+/g, '-');;
    return (
        <Link className="p-flink" 
                to={{
                    pathname: `/${catnameurl}/${posts.titleurl}/${catId}/${posts.id}`,
                    state: {
                    catsurl: catnameurl,
                    posttitleurl: posts.titleurl,
                    id: posts.id,
                    biocat: catId,
                    },
                }} 
                title={posts.title}> 
            <img loading="lazy" decoding="async" width="800" height="568" src={`${SERVER_IMG_PATH}/${posts.featuredImage}`} className="featured-img wp-post-image" alt={posts.title} srcset={`${SERVER_IMG_PATH}/${posts.featuredImage}`} sizes="(max-width: 420px) 100vw, 420px" /> 
        </Link> 
    );
}

function catlink(catposts, catId, category_name) {
    const catnameurl = category_name.toLowerCase().replace(/\s+/g, '-');;
    return (
        <Link className="p-category category-id-27" 
            to={{
                pathname: `/${catnameurl}`,
                state: { catId: catId }
            }}
            rel="category">{category_name}</Link> 
    );
}

function biolink(bioposts, catId, category_name) {
    const catnameurl = category_name.toLowerCase().replace(/\s+/g, '-');;
    return (
        <Link className="p-url"  rel="bookmark" 
                to={{
                    pathname: `/${catnameurl}/${bioposts.titleurl}/${catId}/${bioposts.id}`,
                    state: {
                    catsurl: catnameurl,
                    posttitleurl: bioposts.titleurl,
                    id: bioposts.id,
                    biocat: catId,
                    },
                }} 
                title={bioposts.title}>{bioposts.title}
        </Link> 
    );
}

export default CategoryContant;