import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { SERVER_PATH } from "../AppConstants";

export const AuthContext = createContext();

export const AuthContexProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const loginurl = SERVER_PATH + "/auth/login";
  const logouturl = SERVER_PATH + "/auth/logout";

  const login = async (inputs) => {
    try {
      const formData = new FormData();
      formData.append("email", inputs.email);
      formData.append("password", inputs.password);
      const response = await axios.post(loginurl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true, // This ensures cookies are sent with the request
      });
      setCurrentUser(response.data);
      console.log(response);
  } catch (error) {
      console.error("There was an error!", error.message);
      throw error;
  }
    // const res = await axios.post(loginurl, inputs);
    // console.log(res);
    // setCurrentUser(res.data);
  };

  const logout = async (inputs) => {
    await axios.post(logouturl);
    setCurrentUser(null);
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
