import React from 'react';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const ShareKit = ({ url, title }) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);

  const openPopup = (e, shareUrl) => {
    e.preventDefault(); // Prevent the default link behavior
    const width = 600;
    const height = 400;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    window.open(
      shareUrl,
      '_blank',
      `toolbar=0,status=0,width=${width},height=${height},top=${top},left=${left}`
    );
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
        toast.success('Link copied to clipboard!', {
            autoClose: 3000,
        });
    }).catch(err => {
        toast('Failed to copy:' + err, {
            type:'error',
            autoClose: 3000
        });
    });
  };

  const handleShareMore = () => {
    if (navigator.share) {
      navigator.share({
        title: title,
        url: url,
      }).catch((error) => {
        toast('Error sharing:' + error, {
            type:'error',
            autoClose: 3000
        });
      });
    } else {
        toast('Web Share API is not supported in this browser.', {
            type:'error',
            autoClose: 3000
        });
    }
  };

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const twitterUrl = `https://twitter.com/intent/ttweet?url=${encodedUrl}&text=${encodedTitle}`;
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
  const whatsappUrl = `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`;
  const redditUrl = `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`;

  return (
    <div className="effect-fadeout">
        <a className="share-action share-trigger icon-facebook" aria-label="Share on Facebook" href={facebookUrl} onClick={(e) => openPopup(e, facebookUrl)} data-title="Facebook" rel="nofollow"><i className="rbi rbi-facebook" aria-hidden="true"></i></a> 
        <a className="share-action share-trigger icon-twitter" aria-label="Share on Twitter" href={twitterUrl} onClick={(e) => openPopup(e, twitterUrl)} data-title="Twitter" rel="nofollow"> <i className="rbi rbi-twitter" aria-hidden="true"></i></a>
        <a className="share-action icon-whatsapp is-web" aria-label="Share on Whatsapp" href={whatsappUrl} onClick={(e) => openPopup(e, whatsappUrl)} target="_blank" data-title="WhatsApp" rel="nofollow"><i className="rbi rbi-whatsapp" aria-hidden="true"></i></a>
        <a className="share-action share-trigger icon-reddit" aria-label="Share on Reddit" href={redditUrl} onClick={(e) => openPopup(e, redditUrl)} data-title="Reddit" rel="nofollow"><i className="rbi rbi-reddit" aria-hidden="true"></i></a>  
        <a className="share-action icon-copy copy-trigger" aria-label="Copy Link" onClick={copyToClipboard} data-copied="Copied!" data-link={url} rel="nofollow" data-copy="Copy Link"><i className="rbi rbi-link-o" aria-hidden="true"></i></a> 
        <a className="share-action native-share-trigger more-icon" aria-label="More" onClick={handleShareMore} data-link={url} data-ptitle={title} data-title="More" rel="nofollow"><i className="rbi rbi-more" aria-hidden="true"></i></a>
        <ToastContainer />
    </div>
  );
};


export const SideShareKit = ({ url, title }) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);

  const openPopup = (e, shareUrl) => {
    e.preventDefault(); // Prevent the default link behavior
    const width = 600;
    const height = 400;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    window.open(
      shareUrl,
      '_blank',
      `toolbar=0,status=0,width=${width},height=${height},top=${top},left=${left}`
    );
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
        toast.success('Link copied to clipboard!', {
            autoClose: 3000,
        });
    }).catch(err => {
        toast('Failed to copy:' + err, {
            type:'error',
            autoClose: 3000
        });
    });
  };

  const handleShareMore = () => {
    if (navigator.share) {
      navigator.share({
        title: title,
        url: url,
      }).catch((error) => {
        toast('Error sharing:' + error, {
            type:'error',
            autoClose: 3000
        });
      });
    } else {
        toast('Web Share API is not supported in this browser.', {
            type:'error',
            autoClose: 3000
        });
    }
  };

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const twitterUrl = `https://twitter.com/intent/ttweet?url=${encodedUrl}&text=${encodedTitle}`;
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
  const whatsappUrl = `https://wa.me/?text=${encodedTitle}%20${encodedUrl}`;
  const redditUrl = `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`;

  return (
    <div>
      <div className="l-shared-sec">
          <div className="l-shared-sec">
            <div className="l-shared-header meta-text"> <i className="rbi rbi-share" aria-hidden="true"></i><span className="share-label">SHARE</span> </div>
            <div className="l-shared-items effect-fadeout is-color"> 
              <a className="share-action share-trigger icon-facebook" aria-label="Share on Facebook" href={facebookUrl} onClick={(e) => openPopup(e, facebookUrl)} data-title="Facebook" data-gravity="w" rel="nofollow"><i className="rbi rbi-facebook" aria-hidden="true"></i></a> 
              <a className="share-action share-trigger icon-twitter" aria-label="Share on Twitter" href={twitterUrl} onClick={(e) => openPopup(e, twitterUrl)} data-title="Twitter" data-gravity="w" rel="nofollow"> <i className="rbi rbi-twitter" aria-hidden="true"></i></a> 
              <a className="share-action icon-whatsapp is-web" aria-label="Share on Whatsapp" href={whatsappUrl} onClick={(e) => openPopup(e, whatsappUrl)} target="_blank" data-title="WhatsApp" data-gravity="w" rel="nofollow"><i className="rbi rbi-whatsapp" aria-hidden="true"></i></a> 
              <a className="share-action icon-copy copy-trigger" aria-label="Copy Link" onClick={copyToClipboard} data-copied="Copied!" data-link={url} rel="nofollow" data-copy="Copy Link" data-gravity="w"><i className="rbi rbi-link-o" aria-hidden="true"></i></a> 
              <a className="share-action native-share-trigger more-icon" aria-label="More" onClick={handleShareMore} data-link={url} data-ptitle={title} data-title="More" data-gravity="w" rel="nofollow"><i className="rbi rbi-more" aria-hidden="true"></i></a> 
            </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
