import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
//import {useSelector} from "react-redux";
import InspiringLeaders from "../assets/images/inspiring-leaders-biography.png";
import { InitialCatsArray } from "../AppConstants";

const Footer = () => {
    //const Footercats = useSelector((state)=> state.catsInfo.cats);
    //console.log(InitialCatsArray);('none-m-backtop');      
    return (
        <div>
            <footer className="footer-wrap rb-section left-dot footer-etemplate">
                <div data-elementor-type="wp-post" data-elementor-id="50415" className="elementor elementor-50415">
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-b4dae32 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="b4dae32" data-element_type="section">
                        <div className="elementor-container elementor-column-gap-custom">
                            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-74ce873" data-id="74ce873" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-06dbd43 elementor-widget elementor-widget-foxiz-logo" data-id="06dbd43" data-element_type="widget" data-widget_type="foxiz-logo.default">
                                        <div className="elementor-widget-container">
                                            <div className="the-logo"> 
                                                <Link to="/"> 
                                                    <img loading="eager" decoding="async" data-mode="default" width="544" height="180" src={InspiringLeaders} alt="Inspiring Leaders" /> 
                                                    <img loading="eager" decoding="async" data-mode="dark" width="544" height="180" src={InspiringLeaders} alt="Inspiring Leaders" /> 
                                                </Link> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-2d9d680 elementor-widget elementor-widget-text-editor" data-id="2d9d680" data-element_type="widget" data-widget_type="text-editor.default">
                                        <div className="elementor-widget-container">
                                            <p><span className='footer-span'>Inspiring Leaders: Your Passport to Stardom! Dive into the glamorous world of celebrity news with our exclusive scoops, red carpet revelations, and inside glimpses into the lives of your favorite stars. Stay in the loop – where fame meets fascination!</span></p>
                                        </div>
                                    </div>
                                    {/* <div className="elementor-element elementor-element-1d2049c is-divider-0 elementor-widget elementor-widget-foxiz-quick-links" data-id="1d2049c" data-element_type="widget" data-widget_type="foxiz-quick-links.default">
                                        <div className="elementor-widget-container">
                                            <div id="uid_1d2049c" className="block-wrap block-qlinks qlayout-1 effect-underline res-nowrap qlinks-scroll meta-s-">
                                                <ul className="qlinks-inner">
                                                    <li className="qlink qlinks-heading">
                                                        <div className="qlink-label">Categories: </div>
                                                    </li>
                                                    <li className="qlink h5"><a href="category/celebrity-biography.html">Celebrities</a></li>
                                                    <li className="qlink h5"><a href="category/entertainment.html">Political Leaders</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-c914dd4" data-id="c914dd4" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-817b451 elementor-widget elementor-widget-foxiz-heading" data-id="817b451" data-element_type="widget" data-widget_type="foxiz-heading.default">
                                        <div className="elementor-widget-container">
                                            <div id="uid_817b451" className="block-h heading-layout-2">
                                                <div className="heading-inner">
                                                    <h4 className="heading-title"><span className="quicklink">Quick Links</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-1463f03 elementor-widget elementor-widget-foxiz-sidebar-menu" data-id="1463f03" data-element_type="widget" data-widget_type="foxiz-sidebar-menu.default">
                                        <div className="elementor-widget-container">
                                            <ul id="menu-footer-menu" className="sidebar-menu">
                                                {
                                                    InitialCatsArray.map((categoryObj, i) => {
                                                        const keyval = Object.keys(categoryObj)[0];
                                                        const value = categoryObj[keyval];
                                                        if (keyval !== '0') {
                                                            return (Catmenulink(i, value));
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-06ae006" data-id="06ae006" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-f942f1a elementor-widget elementor-widget-foxiz-heading" data-id="f942f1a" data-element_type="widget" data-widget_type="foxiz-heading.default">
                                        <div className="elementor-widget-container">
                                            <div id="uid_f942f1a" className="block-h heading-layout-2">
                                                <div className="heading-inner">
                                                    <h4 className="heading-title"><span className="quicklink">About Us</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-e897e62 elementor-widget elementor-widget-foxiz-sidebar-menu" data-id="e897e62" data-element_type="widget" data-widget_type="foxiz-sidebar-menu.default">
                                        <div className="elementor-widget-container">
                                            <ul id="menu-header-menu-2" className="sidebar-menu">
                                                <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-515">
                                                    <Link to="/about-us"><span>About Us</span></Link>
                                                </li>
                                                <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-515">
                                                    <Link to="/our-terms"><span>Terms of Use</span></Link>
                                                </li>
                                                <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-43854">
                                                    <Link to="/dmca-policy"><span>DMCA Policy</span></Link>
                                                </li>
                                                <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-43168">
                                                    <Link to="/privacy-policy"><span>Privacy Policy</span></Link>
                                                </li>
                                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-49848">
                                                    <Link to="/contact-us"><span> Contact Us</span></Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-1314585 elementor-widget elementor-widget-foxiz-social-list" data-id="1314585" data-element_type="widget" data-widget_type="foxiz-social-list.default">
                                        <div className="elementor-widget-container">
                                            <div className="header-social-list wnav-holder"><a className="social-link-facebook" aria-label="Facebook" data-title="Facebook" href="#" target="_blank" rel="noopener"><i className="rbi rbi-facebook" aria-hidden="true"></i></a><a className="social-link-twitter" aria-label="Twitter" data-title="Twitter" href="#" target="_blank" rel="noopener"><i className="rbi rbi-twitter" aria-hidden="true"></i></a><a className="social-link-youtube" aria-label="YouTube" data-title="YouTube" href="#" target="_blank" rel="noopener"><i className="rbi rbi-youtube" aria-hidden="true"></i></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-1996ad2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1996ad2" data-element_type="section">
                        <div className="elementor-container elementor-column-gap-custom">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0fb78e8" data-id="0fb78e8" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-5155754 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="5155754" data-element_type="widget" data-widget_type="divider.default">
                                        <div className="elementor-widget-container">
                                            <div className="elementor-divider"> <span className="elementor-divider-separator"> </span> </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-f4fb349 elementor-widget elementor-widget-text-editor" data-id="f4fb349" data-element_type="widget" data-widget_type="text-editor.default">
                                        <div className="elementor-widget-container">
                                            <p>© Inspiring Leaders || All Rights Reserved</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </footer>
        </div>
    );
};

function Catmenulink(catId, category_name) {
    const catnameurl = category_name.toLowerCase().replace(/\s+/g, '-');;
    return (
        <li id="menu-item-18345" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18345">
            <Link to={{
                    pathname: `/${catnameurl}`,
                    state: { catId: catId }
                }}>
                <span>{category_name}</span>
            </Link>
        </li>
    );
}

export default Footer;