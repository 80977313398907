import React, { useState, useEffect } from 'react';
import '../assets/css/dmca-Policy-page.css';

const DMCAPolicy = ( ) => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []); // Empty dependency array means this runs once on mount

  return (
        <div className="site-wrap">
            <div className="single-page without-sidebar sticky-sidebar">
                <header className="page-header page-header-1 edge-padding rb-container">
                    <h1 className="s-title">DMCA Policy</h1>
                </header>
                <div className="rb-small-container edge-padding">
                    <div className="grid-container">
                        <div className="s-ct">
                            <div className="s-ct-inner">
                                <div className="e-ct-outer">
                                    <div className="entry-content rbct">
                                        <h2><strong>DMCA Policy for Inspiring Leaders</strong></h2>
                                        <p>At Inspiring Leaders, all content published on our website is original and intended for educational purposes. Our team takes pride in producing well-researched, original content. However, if we inadvertently publish content, images, or videos that duplicate material from your website, we take such matters seriously. If you are a legal copyright holder or an authorized agent and believe that content on our website infringes on your copyright and is not covered by “Fair Use,” please notify us by emailing inspiringleaders59@gmail.com. Provide detailed information about the allegedly copied content, and we will promptly investigate and remove any duplicate content if necessary.</p>
                                        <h3><strong>To file a DMCA takedown request on our website, please include the following:</strong></h3>
                                        <ul>
                                            <li>A digital or physical signature of the copyright owner or an authorized representative.</li>
                                            <li>The URL of the allegedly copied content along with a full description of the copyrighted material.</li>
                                            <li>Your full name, email address, physical address, and a contact number (mobile or telephone).</li>
                                            <li>A statement confirming that the information provided is accurate and that you are the copyright owner or have the authority to act on behalf of the owner.</li>
                                        </ul>
                                        <p><strong>Note:</strong>&nbsp;These requirements are subject to change without prior notice. Please regularly review this page for updates to our DMCA policy. <img decoding="async" className="emoji" role="img" draggable="false" src="https://s.w.org/images/core/emoji/12.0.0-1/svg/1f642.svg" alt="?" /></p>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default DMCAPolicy;
