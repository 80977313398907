import {
    createSlice
} from "@reduxjs/toolkit";

const initialcats = [["0" , "Select Category"], ["1" , "Founder Stories"], ["2" , "Entrepreneurs"], ["3" , "Business Leaders"], ["4" , "Women Entrepreneurs"], ["5" , "Celebrities"], ["6" , "Political Leaders"]];

const initialState = {
    cats: initialcats,
    // cats: [ "Founder Stories", "Entrepreneur", "Business Leaders", "Women Entrepreneur", "Celebrities", "Political Leaders"],
};

export const catSlice = createSlice({
    name: "cats",
    initialState,
    reducers: {
        setCats: (state, action) => {
            state.cats = [...state.cats, action.payload];
        },
        deleteCats: (state, action) => {
            state.cats = state.cats.filter(
                (cat, index) => index !== action.payload
            );
        },
    },
});

export const { setCats, deleteCats } = catSlice.actions;

export default catSlice.reducer;