import React, { useState, useEffect } from 'react';
import '../assets/css/dmca-Policy-page.css';

const PrivacyPolicy = ( ) => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []); // Empty dependency array means this runs once on mount
  return (
        <div className="site-wrap">
            <div className="single-page without-sidebar sticky-sidebar">
                <header className="page-header page-header-1 edge-padding rb-container">
                    <h1 className="s-title">Privacy Policy</h1>
                </header>
                <div className="rb-small-container edge-padding">
                    <div className="grid-container">
                        <div className="s-ct">
                            <div className="s-ct-inner">
                                <div className="e-ct-outer">
                                    <div className="entry-content rbct">
                                        <h2><strong>Privacy Policy for Inspiring Leaders</strong></h2>
                                        <p>At&nbsp;Inspiring Leaders, we prioritize the privacy of our visitors. This Privacy Policy outlines the types of personal information we collect and how we use it.</p>
                                        <p><span style={{color: '#ffaa12'}}><strong>Log Files</strong></span><br/> Like many websites, Inspiring Leaders uses log files to collect information. The data in these files may include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date/time stamps, referring/exit pages, and the number of clicks. This information helps us analyze trends, manage the site, track users’ movements on the site, and gather demographic data. Importantly, IP addresses and other such information are not linked to any personally identifiable details.</p>
                                        <p><span style={{color: '#ffaa12'}}><strong>Cookies and Web Beacons</strong></span><br/> Inspiring Leaders uses cookies to store information about visitors’ preferences, record user-specific information on the pages accessed or visited, and customize webpage content based on visitors’ browser type or other information sent via their browser.</p>
                                        <p><span style={{color: '#ffaa12'}}><strong>DoubleClick DART Cookie</strong></span><br/>Google, as a third-party vendor, uses cookies to serve ads on Inspiring Leaders. Google’s use of the DART cookie enables it to serve ads to users based on their visits to Inspiring Leaders and other websites. Users can opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at this URL: http://www.google.com/privacy_ads.html.</p>
                                        <p><span style={{color: '#ffaa12'}}><strong>Cookies &amp; 3rd Party Advertisements</strong></span></p>
                                        <p>We allow third-party companies to serve ads and/or collect anonymous information when you visit our website. These companies may use non-personally identifiable information (e.g., clickstream data, browser type, time and date, and the subject of advertisements clicked or scrolled over) during your visits to this and other websites. This information is used to provide advertisements about goods and services that may be of greater interest to you. Typically, these companies use a cookie or third-party web beacon to collect this data.</p>
                                        <p>Some of our advertising partners may use cookies and web beacons on our site, including:</p>
                                        <p><span style={{color: '#ffaa12'}}><strong>Google Adsense</strong></span></p>
                                        <p>TThese third-party ad servers or ad networks use technology to deliver advertisements and links that appear on Inspiring Leaders directly to your browser. When this happens, they automatically receive your IP address. Other technologies (such as cookies, JavaScript, or web beacons) may also be employed by these third-party ad networks to measure the effectiveness of their advertisements and/or to personalize the advertising content you see.</p>
                                        <p>Inspiring Leaders does not have access to or control over the cookies used by third-party advertisers.</p>
                                        <p>For more detailed information on these third-party ad servers' practices and how to opt out of certain practices, please refer to their respective privacy policies. The privacy policy of Inspiring Leaders does not apply to these other advertisers or websites, and we cannot control their activities.</p>
                                        <p>If you wish to disable cookies, you can do so through your browser’s settings. More detailed information about cookie management with specific browsers can be found on the browsers’ respective websites.</p>
                                        <p><span style={{color: '#ffaa12'}}><strong>Contact Information:</strong></span></p>
                                        <p>If you have any questions or need more information about our Privacy Policy, please feel free to contact us via email at <strong>info@inspiringleaders.ai</strong></p>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default PrivacyPolicy;
