import React, { useState, useEffect, Suspense  } from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import InspiringLeaders from "../assets/images/inspiring-leaders-biography.png";
import RoyalPathCircle from "../assets/images/royal-path-circle.png";
import RoyalEdge from "../assets/images/royal-edge.png";
import { SERVER_PATH, SERVER_IMG_PATH} from "../AppConstants";
import '../assets/css/style.css';
import '../assets/css/index.css';

const Indexcontant = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        mainTopBio: [],
        topCatBioSection1: [],
        topCatBioSection2: [],
        mainLatestBio: [],
    });
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
          try {
            const response  = await axios.get(`${SERVER_PATH}/indexbios`);
            if (response.data) {
                setData(response.data);
                //console.log(response.data);
              } else {
                console.error("Post not found");
                // Handle case where post is not found, like navigating to a "Not Found" page
              }
          } catch (err) {
            console.log(err);
          } finally {
            setLoading(false); // End loading
          }
        };
        fetchData();
    },[]);
    useEffect(() => {
        // Base64 image string
        const base64Image = InspiringLeaders;
        const pageTitle = "Inspiring Leaders - Biography of Famous Celebrity and Trending Personality";
        const description = "Inspiring Leaders is one-stop destination for the biography of Celebrity and Trending Personality";

        // Set the page title
        document.title = pageTitle;
        // Create meta tags
        const metaImage = document.createElement('meta');
        metaImage.setAttribute('property', 'og:image');
        metaImage.setAttribute('content', base64Image);
        
        const metaWidth = document.createElement('meta');
        metaWidth.setAttribute('property', 'og:image:width');
        metaWidth.setAttribute('content', '1200');
        
        const metaHeight = document.createElement('meta');
        metaHeight.setAttribute('property', 'og:image:height');
        metaHeight.setAttribute('content', '630');

        // Add the description meta tag
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.setAttribute('content', description);   
        
        // Append meta tags to the head
        document.head.appendChild(metaImage);
        document.head.appendChild(metaWidth);
        document.head.appendChild(metaHeight);
        document.head.appendChild(metaDescription);

        // Cleanup function to remove meta tags on component unmount
        return () => {
        document.head.removeChild(metaImage);
        document.head.removeChild(metaWidth);
        document.head.removeChild(metaHeight);
        document.head.removeChild(metaDescription); // Remove the description meta tag
        };
    },[]);
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []); // Empty dependency array means this runs once on mount
    if (loading) {
        return (
            <div className="loading-wrapper">
                <img src={RoyalPathCircle} style={{float: 'left'}} loading="lazy" class="styles_hero_section_circleImg__hsJQL" alt='Inspiring Leaders'></img>
                <p className="loading-text">Gathering insights, please wait...</p>
            </div>
        );
    }
    return (
        <div>
            <div className="site-wrap">
                <div data-elementor-type="wp-page" data-elementor-id="945" className="elementor elementor-945">
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-8c2ec30 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8c2ec30" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shape_divider_bottom&quot;:&quot;waves&quot;}">
                        <img src={RoyalPathCircle} className="styles_hero_section_circleImg__hsJQL" alt='Inspiring Leaders' loading="lazy" />
                        <div className="elementor-container elementor-column-gap-no">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1b8b8e0" data-id="1b8b8e0" data-element_type="column">
                                {/* <MainTopBio /> */}
                                <div className="elementor-widget-wrap elementor-element-populated">
        <section className="elementor-section elementor-inner-section elementor-element elementor-element-230e784 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="230e784" data-element_type="section">
            <div className="elementor-container elementor-column-gap-custom">
                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-8874544" data-id="8874544" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-2523998 elementor-widget elementor-widget-foxiz-overlay-1" data-id="2523998" data-element_type="widget" data-widget_type="foxiz-overlay-1.default">
                            <div className="elementor-widget-container">
                                <div id="uid_2523998" className="block-wrap block-overlay overlay-1 light-overlay-scheme p-gradient rb-columns rb-col-1 rb-tcol-1 rb-mcol-1 ecat-bg-4 meta-s-">
                                    <div className="block-inner">
                                        {data.mainTopBio && data.mainTopBio.length > 0 ? (
                                            data.mainTopBio.slice(0, 1).map((posts) => (
                                                <div key={posts.id} className="p-wrap p-highlight p-overlay-1" data-pid={posts.id}>
                                                    <div className="overlay-holder">
                                                        <div className="p-featured"> 
                                                            {featuredImg(posts)} 
                                                        </div>
                                                        <div className="overlay-wrap">
                                                            <div className="overlay-inner p-content overlay-text">
                                                                <div className="p-categories p-top">
                                                                    {catlink(posts)}
                                                                </div>
                                                                <h2 className="entry-title"> 
                                                                    {biolink(posts)}
                                                                </h2>
                                                                <p class="gh-card-excerpt is-body">{posts.content}</p>
                                                                {/* <div className="p-meta">
                                                                    <div className="meta-inner is-meta"> 
                                                                        <a className="meta-el meta-avatar">
                                                                            <img loading="lazy" decoding="async" src={Profileimg} width="44" height="44" alt="Admin" className="avatar avatar-44 wp-user-avatar wp-user-avatar-44 alignnone photo" />
                                                                        </a> 
                                                                        <span className="meta-el meta-author"> 
                                                                            <a>Admin</a> 
                                                                        </span> 
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No data available</p> // Fallback message if no data
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-730bbfc" data-id="730bbfc" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-36eecfb elementor-widget elementor-widget-foxiz-grid-box-1" data-id="36eecfb" data-element_type="widget" data-widget_type="foxiz-grid-box-1.default">
                            <div className="elementor-widget-container">
                                <div id="uid_36eecfb" className="block-wrap block-grid block-grid-box-1 rb-columns rb-col-2 rb-tcol-2 rb-mcol-1 is-gap-20 ecat-bg-4 meta-s-">
                                    {topPost(data.mainTopBio, 1, 3)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="elementor-element elementor-element-96321ff elementor-widget elementor-widget-foxiz-grid-box-1" data-id="96321ff" data-element_type="widget" data-widget_type="foxiz-grid-box-1.default">
            <div className="elementor-widget-container">
                <div id="uid_96321ff" className="block-wrap block-grid block-grid-box-1 rb-columns rb-col-4 rb-tcol-2 rb-mcol-1 is-gap-custom ecat-bg-4 is-m-list meta-s-">
                    {topPost(data.mainTopBio, 3, 7)}
                </div>
            </div>
        </div>
    </div>
                            </div>
                        </div>
                    </section>
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-b204ec8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="b204ec8" data-element_type="section">
                        <div className="styles_topVectorImage__OV2Yx">
                            <img src={RoyalEdge} alt='Inspiring Leaders' loading="lazy" /></div>
                        <div className="elementor-container elementor-column-gap-custom">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8e927da" data-id="8e927da" data-element_type="column">
                                {/* <TopCatBioSection catId={3} limit={4} /> */}
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-87aeb0c elementor-widget elementor-widget-foxiz-heading" data-id="87aeb0c" data-element_type="widget" data-widget_type="foxiz-heading.default">
                                        <div className="elementor-widget-container">
                                            <div id="uid_87aeb0c" className="block-h heading-layout-19 light-scheme">
                                                <div className="heading-inner">
                                                    <h2 className="heading-title"><span>Business Leaders</span></h2>
                                                    <div className="heading-tagline h6"><span className="heading-tagline-label">Uncover the stories that matter</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-c0b1e24 elementor-widget elementor-widget-foxiz-grid-box-1" data-id="c0b1e24" data-element_type="widget" data-widget_type="foxiz-grid-box-1.default">
                                        <div className="elementor-widget-container">
                                            <div id="uid_c0b1e24" className="block-wrap block-grid block-grid-box-1 rb-columns rb-col-4 rb-tcol-2 rb-mcol-1 is-gap-custom ecat-bg-4 is-m-list meta-s-">
                                                {topPost(data.topCatBioSection2, 0, 4)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-9e4efb4 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9e4efb4" data-element_type="section">
                        <div className="elementor-container elementor-column-gap-custom">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8b1ec52" data-id="8b1ec52" data-element_type="column">
                                {/* <TopCatBioSection catId={2} limit={4} /> */}
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-87aeb0c elementor-widget elementor-widget-foxiz-heading" data-id="87aeb0c" data-element_type="widget" data-widget_type="foxiz-heading.default">
                                        <div className="elementor-widget-container">
                                            <div id="uid_87aeb0c" className="block-h heading-layout-19 light-scheme">
                                                <div className="heading-inner">
                                                    <h2 className="heading-title"><span>Entrepreneurs</span></h2>
                                                    <div className="heading-tagline h6"><span className="heading-tagline-label">Let us be your guide to Entrepreneurs</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-c0b1e24 elementor-widget elementor-widget-foxiz-grid-box-1" data-id="c0b1e24" data-element_type="widget" data-widget_type="foxiz-grid-box-1.default">
                                        <div className="elementor-widget-container">
                                            <div id="uid_c0b1e24" className="block-wrap block-grid block-grid-box-1 rb-columns rb-col-4 rb-tcol-2 rb-mcol-1 is-gap-custom ecat-bg-4 is-m-list meta-s-">
                                                {topPost(data.topCatBioSection1, 0, 4)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-155feec elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="155feec" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shape_divider_top&quot;:&quot;waves&quot;,&quot;shape_divider_bottom&quot;:&quot;waves&quot;}">
                        <div className="elementor-container elementor-column-gap-custom">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d74210a" data-id="d74210a" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-6c721e0 elementor-widget elementor-widget-foxiz-heading" data-id="6c721e0" data-element_type="widget" data-widget_type="foxiz-heading.default">
                                        <div className="elementor-widget-container">
                                            <div id="uid_6c721e0" className="block-h heading-layout-19">
                                                <div className="heading-inner">
                                                    <h2 className="heading-title"><span>Trending Insights</span></h2>
                                                    <div className="heading-tagline h6"><span className="heading-tagline-label">Explore the Inspiring Leaders</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-837a14a elementor-widget elementor-widget-foxiz-grid-box-1" data-id="837a14a" data-element_type="widget" data-widget_type="foxiz-grid-box-1.default">
                                        <div className="elementor-widget-container">
                                            <div id="uid_837a14a" className="block-wrap block-grid block-grid-box-1 rb-columns rb-col-4 rb-tcol-2 rb-mcol-1 is-gap-custom ecat-bg-4 is-m-list meta-s-">
                                                {/* <MainLatestBio /> */}
                                                {topPost(data.mainLatestBio, 0, 16)}
                                                {/* <div className="pagination-wrap pagination-loadmore"> <a href="#" className="loadmore-trigger"><span>Show More</span><i className="rb-loader" aria-hidden="true"></i></a> </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

function topPost(postsval, start, end) {
    const selectedtopPosts = postsval.slice(start, end);
    return (
        <div className="block-inner">
            {selectedtopPosts.map((postval, index) => (
            <div className="p-wrap p-grid p-box p-grid-box-1 box-shadow" data-pid="53735">
                <div className="grid-box">
                    <div className="feat-holder overlay-text">
                        <div className="p-featured"> 
                            {featuredImg(postval)} 
                        </div>
                        <div className="p-categories p-top">
                            {catlink(postval)}
                        </div>
                    </div>
                    <h4 className="entry-title"> 
                        {biolink(postval)}
                    </h4>
                    <div className="p-meta">
                        <div className="meta-inner is-meta"> 
                        <p class="gh-card-excerpt is-body">{postval.content}</p>
                            {/* <a className="meta-el meta-avatar">
                                <img loading="lazy" decoding="async" src={Profileimg} width="44" height="44" alt="Admin" className="avatar avatar-44 wp-user-avatar wp-user-avatar-44 alignnone photo" />
                            </a> 
                            <span className="meta-el meta-author"> 
                                <a >Admin</a> 
                            </span>  */}
                        </div>
                    </div>
                </div>
            </div>
            ))}
        </div>
    );
  }

function featuredImg(posts) {
    const catId = posts.category;
    const category_name = posts.category_name;
    const catnameurl = category_name.toLowerCase().replace(/\s+/g, '-');;
    return (
        <Link className="p-flink" 
                to={{
                    pathname: `/${catnameurl}/${posts.titleurl}/${catId}/${posts.id}`,
                    state: {
                    catsurl: catnameurl,
                    posttitleurl: posts.titleurl,
                    id: posts.id,
                    biocat: catId,
                    },
                }} 
                title={posts.title}> 
            <img loading="lazy" decoding="async" width="800" height="568" src={`${SERVER_IMG_PATH}/${posts.featuredImage}`} className="featured-img wp-post-image" alt={posts.title} srcset={`${SERVER_IMG_PATH}/${posts.featuredImage} 615w`} sizes="(max-width: 800px) 100vw, 800px" /> 
        </Link> 
    );
  }

  function catlink(catposts) {
    const catId = catposts.category;
    const category_name = catposts.category_name;
    const catnameurl = category_name.toLowerCase().replace(/\s+/g, '-');;
    return (
        <Link className="p-category category-id-27" 
            to={{
                pathname: `/${catnameurl}`,
                state: { catId: catId }
            }}
            rel="category">{category_name}</Link> 
    );
  }

  function biolink(bioposts) {
    const catId = bioposts.category;
    const category_name = bioposts.category_name;
    const catnameurl = category_name.toLowerCase().replace(/\s+/g, '-');;
    return (
        <Link className="p-url"  rel="bookmark" 
                to={{
                    pathname: `/${catnameurl}/${bioposts.titleurl}/${catId}/${bioposts.id}`,
                    state: {
                    catsurl: catnameurl,
                    posttitleurl: bioposts.titleurl,
                    id: bioposts.id,
                    biocat: catId,
                    },
                }} 
                title={bioposts.title}>{bioposts.title}
        </Link> 
    );
  }

export default Indexcontant;