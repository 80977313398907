import React, { useEffect, useState } from "react";
import Profileimg from "../assets/images/favicon.png";
import RoyalPathCircle from "../assets/images/royal-path-circle.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {useSelector} from "react-redux"
import DOMPurify from 'dompurify';
//import { AuthContext } from "../context/authContext";
import { ShareKit, SideShareKit } from '../components/TopShareKit';
import { SERVER_PATH, CLIENT_PATH, APPNAME_PATH, SERVER_IMG_PATH } from "../AppConstants";
import '../assets/css/biography-page.css';

const SinglePost = () => {
    //console.log(SERVER_PATH);
    //const [post, setPost] = useState({});
    const [post, setPost] = useState({
        id: '',
        title: '',
        titleurl: '',
        content: '',
        category: '',
        featuredImage: '',
        featuredImage1: '',
        publicationDate: '',
        Tags: '',
        delflag: '1',
        // Biography
        shortBio1: '',
        shortBio2: '',
        // Early life
        birthDetails: '',
        childhoodExperiences: '',
        earlyEducation: '',
        communityEnvironment: '',
        personalityDevelopment: '',
        earlyChallenges: '',
        //age / favorite
        age: '',
        height: '',
        weight: '',
        color: '',
        favoriteActors: '',
        favoriteActresses: '',
        favoriteSingers: '',
        favoriteSports: '',
        favoriteFood: '',
        favoriteDestination: '',
        favoriteBooks: '',
        favoriteMusic: '',
        favoriteMovies: '',
        favoriteHobbies: '',
        favoriteTVShows: '',
        favoriteGames: '',
        // educational career
        schoolEducation: '',
        schoolYears: '',
        schoolExtracurricular: '',
        collegeEducation: '',
        collegeYears: '',
        collegeExtracurricular: '',
        postgraduateEducation: '',
        postgraduateYears: '',
        postgraduateExtracurricular: '',
        additionalEducation: '',
        additionalYears: '',
        additionalExtracurricular: '',
        notableAchievements: '',
        // business career
        companyName: "",
        netWorth: "",
        dateOfEstablishment: "",
        industry: "",
        initialVision: "",
        majorMilestones: "",
        challenges: "",
        innovations: "",
        businessGrowth: "",
        keyProducts: "",
        impact: "",
        awards: "",
        futureGoals: "",
        businessPhotos: "",
        currentPosition: "",
        yearsOfService: "",
        keyResponsibilities: "",
        strategicDecisions: "",
        impactOnGrowth: "",
        leadershipStyle: "",
        mentorship: "",
        majorProjects: "",
        businessprofessionalNetworks: "",
        challengesFaced: "",
        supportNetworks: "",
        growthStrategies: "",
        impactOnCommunity: "",
    
        // New fields for Celebrities in Business
        businessVentures: "",
        brandCollaborations: "",
        productLines: "",
        investmentPortfolio: "",
        roleInBusiness: "",
        impactOnBrand: "",
        marketingStrategies: "",
        philanthropicActivities: "",
    
        // New fields for Political Leaders
        politicalPosition: "",
        businessInterests: "",
        economicPolicies: "",
        impactOnBusiness: "",
        keyDecisions: "",
        publicCollaborations: "",
        projectsInitiatives: "",
        professionalNetworks: "",
        // Personal Life
        dateOfBirth: '',
        placeOfBirth: '',
        zodiacSign: '',
        nationality: '',
        homeTown: '',
        languages: '',
        familyBackground: '',
        culturalHeritage: '',
        historicalEvents: '',
        significantLifeEvents: '',
        earlyInfluences: '',
        religiousBeliefs: '',
        personalMilestones: '',
        communityInvolvement: '',
        familyStories: '',
        // 
        maritalStatus: '',
        gender: 'Male', // Default to Male
        spouseName: '',
        spousePhoto: null,
        fatherName: '',
        fatherPhoto: null,
        motherName: '',
        motherPhoto: null,
        children: [{ name: '', photo: null }],
        brothers: [{ name: '', photo: null }],
        sisters: [{ name: '', photo: null }],
        girlfriends: [{ name: '', photo: null }],
        boyfriends: [{ name: '', photo: null }],
        relatives: [{ name: '', photo: null }],
        additionalInfo: '',
        // Political career
        career: '',
        keyIssues: '',
        policyProposals: '',
        campaignAnnouncements: '',
        eventsAndRallies: '',
        volunteerAndDonationInfo: '',
        pressReleases: '',
        mediaCoverage: '',
        socialMediaUpdates: '',
        positionPapers: '',
        opinionArticles: '',
        speechesAndDebates: '',
        interviewsAndQASessions: '',
        campaignAds: '',
        supporterTestimonials: '',
        endorsements: '',
        constituentServices: '',
        feedbackAndSurveys: '',
        communityInitiatives: '',
        infographics: '',
        photographs: '',
        memesAndGraphics: '',
        liveStreams: '',
        virtualTownHalls: '',
        // Awards and Achievements
        industryAwards: '',
        leadershipAwards: '',
        innovationAwards: '',
        communityImpact: '',
        publicRecognition: '',
        philanthropicAchievements: '',
        careerMilestones: '',
        // Some Interesting Facts
        uniqueAchievements: '',
        personalInsights: '',
        culturalImpact: '',
        historicalSignificance: '',
        // Social Media Profile(s)
        instagram: '',
        twitter: '',
        facebook: '',
        linkedin: '',
    });
    const [catname, setCatname] = useState();
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState('');
    const [extractedTexts, setExtractedTexts] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const cats = useSelector((state)=> state.catsInfo.cats);
    const { catsurl, posturl, biocat, id } = useParams();
    const postId = id;
    const catnameurl = catsurl;
    useEffect(() => {
        Object.keys(cats).forEach((key) => {
          if (key === biocat) {
            setCatname(cats[biocat][1]);
          }
        });
      }, [cats, biocat, setCatname]); 
    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };
    useEffect(() => {
        const fetchData = async () => {
          try {
            const responseData = await axios.get(`${SERVER_PATH}/posts/${postId}`);
            if (responseData.data.title) {
                //setPost(res.data); // Set the post data if found
                // Set form data based on responseData
                setPost({
                    id: responseData.data.id || '',
                    category_name: responseData.data.category_name || '',
                    title: responseData.data.title || '',
                    titleurl: responseData.data.titleurl || '',
                    content: responseData.data.content || '',
                    category: responseData.data.category || '',
                    featuredImage: responseData.data.featuredImage || '',
                    featuredImage1: responseData.data.featuredImage1 || '',
                    publicationDate: responseData.data.publicationDate || '',
                    Tags: responseData.data.Tags || '',
                    delflag: responseData.data.delflag || '1',
                    shortBio1: responseData.data.shortBio1 || '',
                    shortBio2: responseData.data.shortBio2 || '',
                    birthDetails: responseData.data.birthDetails || '',
                    childhoodExperiences: responseData.data.childhoodExperiences || '',
                    personalityDevelopment: responseData.data.personalityDevelopment || '',
                    earlyChallenges: responseData.data.earlyChallenges || '',
                    age: responseData.data.age || '',
                    height: responseData.data.height || '',
                    weight: responseData.data.weight || '',
                    color: responseData.data.color || '',
                    favoriteActors: responseData.data.favoriteActors || '',
                    favoriteActresses: responseData.data.favoriteActresses || '',
                    favoriteSingers: responseData.data.favoriteSingers || '',
                    favoriteSports: responseData.data.favoriteSports || '',
                    favoriteFood: responseData.data.favoriteFood || '',
                    favoriteDestination: responseData.data.favoriteDestination || '',
                    favoriteBooks: responseData.data.favoriteBooks || '',
                    favoriteMusic: responseData.data.favoriteMusic || '',
                    favoriteMovies: responseData.data.favoriteMovies || '',
                    favoriteHobbies: responseData.data.favoriteHobbies || '',
                    favoriteTVShows: responseData.data.favoriteTVShows || '',
                    favoriteGames: responseData.data.favoriteGames || '',
                    schoolEducation: responseData.data.schoolEducation || '',
                    schoolYears: responseData.data.schoolYears || '',
                    schoolExtracurricular: responseData.data.schoolExtracurricular || '',
                    collegeEducation: responseData.data.collegeEducation || '',
                    collegeYears: responseData.data.collegeYears || '',
                    collegeExtracurricular: responseData.data.collegeExtracurricular || '',
                    postgraduateEducation: responseData.data.postgraduateEducation || '',
                    postgraduateYears: responseData.data.postgraduateYears || '',
                    postgraduateExtracurricular: responseData.data.postgraduateExtracurricular || '',
                    additionalEducation: responseData.data.additionalEducation || '',
                    additionalYears: responseData.data.additionalYears || '',
                    additionalExtracurricular: responseData.data.additionalExtracurricular || '',
                    notableAchievements: responseData.data.notableAchievements || '',
                    companyName: responseData.data.companyName || '',
                    netWorth: responseData.data.netWorth || '',
                    dateOfEstablishment: responseData.data.dateOfEstablishment || '',
                    industry: responseData.data.industry || '',
                    initialVision: responseData.data.initialVision || '',
                    majorMilestones: responseData.data.majorMilestones || '',
                    challenges: responseData.data.challenges || '',
                    innovations: responseData.data.innovations || '',
                    businessGrowth: responseData.data.businessGrowth || '',
                    keyProducts: responseData.data.keyProducts || '',
                    impact: responseData.data.impact || '',
                    awards: responseData.data.awards || '',
                    futureGoals: responseData.data.futureGoals || '',
                    businessPhotos: responseData.data.businessPhotos || '',
                    currentPosition: responseData.data.currentPosition || '',
                    yearsOfService: responseData.data.yearsOfService || 0,
                    keyResponsibilities: responseData.data.keyResponsibilities || '',
                    strategicDecisions: responseData.data.strategicDecisions || '',
                    impactOnGrowth: responseData.data.impactOnGrowth || '',
                    leadershipStyle: responseData.data.leadershipStyle || '',
                    mentorship: responseData.data.mentorship || '',
                    majorProjects: responseData.data.majorProjects || '',
                    businessprofessionalNetworks: responseData.data.businessprofessionalNetworks || '',
                    challengesFaced: responseData.data.challengesFaced || '',
                    supportNetworks: responseData.data.supportNetworks || '',
                    growthStrategies: responseData.data.growthStrategies || '',
                    impactOnCommunity: responseData.data.impactOnCommunity || '',
                    businessVentures: responseData.data.businessVentures || '',
                    brandCollaborations: responseData.data.brandCollaborations || '',
                    productLines: responseData.data.productLines || '',
                    investmentPortfolio: responseData.data.investmentPortfolio || '',
                    roleInBusiness: responseData.data.roleInBusiness || '',
                    impactOnBrand: responseData.data.impactOnBrand || '',
                    marketingStrategies: responseData.data.marketingStrategies || '',
                    philanthropicActivities: responseData.data.philanthropicActivities || '',
                    politicalPosition: responseData.data.politicalPosition || '',
                    businessInterests: responseData.data.businessInterests || '',
                    economicPolicies: responseData.data.economicPolicies || '',
                    impactOnBusiness: responseData.data.impactOnBusiness || '',
                    keyDecisions: responseData.data.keyDecisions || '',
                    publicCollaborations: responseData.data.publicCollaborations || '',
                    projectsInitiatives: responseData.data.projectsInitiatives || '',
                    professionalNetworks: responseData.data.professionalNetworks || '',
                    dateOfBirth: responseData.data.dateOfBirth || '',
                    placeOfBirth: responseData.data.placeOfBirth || '',
                    zodiacSign: responseData.data.zodiacSign || '',
                    nationality: responseData.data.nationality || '',
                    homeTown: responseData.data.homeTown || '',
                    languages: responseData.data.languages || '',
                    familyBackground: responseData.data.familyBackground || '',
                    culturalHeritage: responseData.data.culturalHeritage || '',
                    historicalEvents: responseData.data.historicalEvents || '',
                    significantLifeEvents: responseData.data.significantLifeEvents || '',
                    earlyInfluences: responseData.data.earlyInfluences || '',
                    religiousBeliefs: responseData.data.religiousBeliefs || '',
                    personalMilestones: responseData.data.personalMilestones || '',
                    communityInvolvement: responseData.data.communityInvolvement || '',
                    maritalStatus: responseData.data.maritalStatus || '',
                    gender: responseData.data.gender || '',
                    spouseName: responseData.data.spouseName || '',
                    spousePhoto: responseData.data.spousePhoto || '',
                    fatherName: responseData.data.fatherName || '',
                    fatherPhoto: responseData.data.fatherPhoto || '',
                    motherName: responseData.data.motherName || '',
                    motherPhoto: responseData.data.motherPhoto || '',
                    children: parseJson(responseData.data.children) || [{ name: '', photo: null }],
                    brothers: parseJson(responseData.data.brothers) || [{ name: '', photo: null }],
                    sisters: parseJson(responseData.data.sisters) || [{ name: '', photo: null }],
                    girlfriends: parseJson(responseData.data.girlfriends) || [{ name: '', photo: null }],
                    boyfriends: parseJson(responseData.data.boyfriends) || [{ name: '', photo: null }],
                    relatives: parseJson(responseData.data.relatives) || [{ name: '', photo: null }],
                    additionalInfo: responseData.data.additionalInfo || '',
                    career: responseData.data.career || '',
                    keyIssues: responseData.data.keyIssues || '',
                    policyProposals: responseData.data.policyProposals || '',
                    campaignAnnouncements: responseData.data.campaignAnnouncements || '',
                    eventsAndRallies: responseData.data.eventsAndRallies || '',
                    volunteerAndDonationInfo: responseData.data.volunteerAndDonationInfo || '',
                    pressReleases: responseData.data.pressReleases || '',
                    mediaCoverage: responseData.data.mediaCoverage || '',
                    socialMediaUpdates: responseData.data.socialMediaUpdates || '',
                    positionPapers: responseData.data.positionPapers || '',
                    opinionArticles: responseData.data.opinionArticles || '',
                    speechesAndDebates: responseData.data.speechesAndDebates || '',
                    interviewsAndQASessions: responseData.data.interviewsAndQASessions || '',
                    campaignAds: responseData.data.campaignAds || '',
                    supporterTestimonials: responseData.data.supporterTestimonials || '',
                    endorsements: responseData.data.endorsements || '',
                    constituentServices: responseData.data.constituentServices || '',
                    feedbackAndSurveys: responseData.data.feedbackAndSurveys || '',
                    communityInitiatives: responseData.data.communityInitiatives || '',
                    infographics: responseData.data.infographics || '',
                    photographs: responseData.data.photographs || '',
                    memesAndGraphics: responseData.data.memesAndGraphics || '',
                    liveStreams: responseData.data.liveStreams || '',
                    virtualTownHalls: responseData.data.virtualTownHalls || '',
                    industryAwards: responseData.data.industryAwards || '',
                    leadershipAwards: responseData.data.leadershipAwards || '',
                    innovationAwards: responseData.data.innovationAwards || '',
                    communityImpact: responseData.data.communityImpact || '',
                    publicRecognition: responseData.data.publicRecognition || '',
                    philanthropicAchievements: responseData.data.philanthropicAchievements || '',
                    careerMilestones: responseData.data.careerMilestones || '',
                    uniqueAchievements: responseData.data.uniqueAchievements || '',
                    personalInsights: responseData.data.personalInsights || '',
                    culturalImpact: responseData.data.culturalImpact || '',
                    historicalSignificance: responseData.data.historicalSignificance || '',
                    instagram: responseData.data.instagram || '',
                    twitter: responseData.data.twitter || '',
                    facebook: responseData.data.facebook || '',
                    linkedin: responseData.data.linkedin || '',
                    category_name: responseData.data.category_name || ''
                });
                //setContent(res.data.content);
                //extractAndSetTexts(res.data.content);
              } else {
                console.error("Post not found");
                // Handle case where post is not found, like navigating to a "Not Found" page
                //navigate('/not-found');
              }
          } catch (err) {
            console.log(err);
          } finally {
            setLoading(false); // Set loading to false once the data is fetched or an error occurs
          }
        };
        fetchData();
    }, [postId]);
    useEffect(() => {
        const base64Image = SERVER_IMG_PATH + '/' + post.featuredImage;
        const pageTitle = `${post.title} Biography, Age, Favorite things, Family, Relationship & More`;
        const description = post.content;
        const author = "Inspiring Leaders";
        const slug = `${catsurl}/${posturl}/${biocat}/${id}`;
        //catsurl, posturl, biocat, id
    
        // Set the page title
        document.title = pageTitle;
    
        // Create and append meta tags
        const metaTags = [
          { name: 'description', content: description },
          { property: 'og:title', content: pageTitle },
          { property: 'og:description', content: description },
          { property: 'og:image', content: base64Image },
          { property: 'og:image:width', content: '1200' },
          { property: 'og:image:height', content: '630' },
          { property: 'og:type', content: 'article' },
        ];
    
        metaTags.forEach(({ name, property, content }) => {
          const metaTag = document.createElement('meta');
          if (name) metaTag.setAttribute('name', name);
          if (property) metaTag.setAttribute('property', property);
          metaTag.setAttribute('content', content);
          document.head.appendChild(metaTag);
        });
    
        // Create and append canonical link
        const canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', `https://www.inspiringleaders.ai/${slug}`);
        document.head.appendChild(canonicalLink);
    
        // Create and append structured data script
        const scriptTag = document.createElement('script');
        scriptTag.type = 'application/ld+json';
        scriptTag.text = JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": post.title,
          "author": author,
          "datePublished": post.publicationDate,
          "mainEntityOfPage": `https://www.inspiringleaders.ai/${slug}`
        });
        document.head.appendChild(scriptTag);
    
        // Cleanup function to remove meta tags, canonical link, and script on component unmount
        return () => {
          metaTags.forEach(({ name, property }) => {
            const selector = name ? `meta[name="${name}"]` : `meta[property="${property}"]`;
            const metaTag = document.head.querySelector(selector);
            if (metaTag) {
              document.head.removeChild(metaTag);
            }
          });
    
          const existingCanonicalLink = document.head.querySelector('link[rel="canonical"]');
          if (existingCanonicalLink) {
            document.head.removeChild(existingCanonicalLink);
          }
    
          const existingScriptTag = document.head.querySelector('script[type="application/ld+json"]');
          if (existingScriptTag) {
            document.head.removeChild(existingScriptTag);
          }
        };
    }, [post.title, post.publicationDate]);

    const handleDelete = async ()=>{
        try {
          await axios.delete(`/posts/${postId}`);
          navigate(`/${catsurl}`)
        } catch (err) {
          console.log(err);
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, [postId]); // Empty dependency array means this runs once on mount

    // Inner function to extract texts between specified tags
    const extractAndSetTexts = (content) => {
        const extractTextsBetweenTags = (text, startTag, endTag) => {
        const regex = new RegExp(`${startTag}(.*?)${endTag}`, 'g');
        let match;
        const results = [];
        while ((match = regex.exec(text)) !== null) {
            results.push(match[1].trim()); // Extract and trim the text between tags
        }
        return results;
        };

        const texts = extractTextsBetweenTags(content, '<h2>', '</h2>');
        setExtractedTexts(texts);

        // Replace the <h2> tags in the content with <h2 id="custom-id"> tags
        const updatedContent = texts.reduce((acc, curr, index) => {
        const id = `heading-${index + 1}`;
        const h2WithId = `<h2 id="${id}">${curr}</h2>`;
        return acc.replace(`<h2>${curr}</h2>`, h2WithId);
        }, content);

        setContent(updatedContent);
    };

    // Helper function to safely parse JSON strings
    const parseJson = (data) => {
        try {
        return JSON.parse(data);
        } catch (error) {
        return [];
        }
    };
    const postUrl = CLIENT_PATH + "/" + catnameurl + "/" + posturl;
    // Helper function to check if all fields in a section are empty
    const isSectionEmpty = (fields) => fields.every(field => !field || field === '');
    //console.log(content);
    // Show animated loading text while data is being fetched
    if (loading) {
        return (
        <div className="loading-wrapper">
            <img src={RoyalPathCircle} class="styles_hero_section_circleImg__hsJQL" loading="lazy" alt="Inspiring Leaders"></img>
            <p className="loading-text">Gathering insights, please wait...</p>
        </div>
        );
    }


    return (
        <div>
            {post.title ? (
                <div className="site-wrap">
                    <div className="single-standard-1 is-sidebar-right sticky-sidebar optimal-line-length">
                        <div className="rb-container edge-padding">
                            <article id="post-30754" className="post-30754 post type-post status-publish format-standard has-post-thumbnail category-celebrity-biography category-movies-celebrity">
                                <header className="single-header">
                                    <aside className="breadcrumb-wrap breadcrumb-navxt s-breadcrumb">
                                        <div className="breadcrumb-inner" vocab="https://schema.org/" typeof="BreadcrumbList"> <span property="itemListElement" typeof="ListItem"><Link property="item" typeof="WebPage" title="Go to Inspiring Leaders - Biography of Famous Celebrity and Trending Personality." to="/" className="home"><span property="name">{APPNAME_PATH + " - Famous Faces and Their Inspiring Journeys"}</span></Link>
                                                <meta property="position" content="1" />
                                            </span> &gt; <span property="itemListElement" typeof="ListItem">
                                                <Link property="item" typeof="WebPage" title={'Go to the ' + catname + ' Biography Category archives.'} 
                                                to={{
                                                    pathname: `/${catnameurl}`,
                                                    state: { catId: biocat }
                                                }}
                                                className="taxonomy category"><span property="name">{catname}</span></Link>
                                                <meta property="position" content="2" />
                                            </span> &gt; <span property="itemListElement" typeof="ListItem"><span property="name" className="post post-post current-item">{post.title}</span>
                                                <meta property="url" content={CLIENT_PATH + '/' + catnameurl} />
                                                <meta property="position" content="4" />
                                            </span></div>
                                    </aside>
                                    <div className="s-cats ecat-bg-4">
                                        <div className="p-categories"><Link className="p-category category-id-27" 
                                        to={{
                                            pathname: `/${catnameurl}`,
                                            state: { catId: biocat }
                                        }}
                                        rel="category">{catname}</Link></div>
                                    </div>
                                    <h1 className="s-title fw-headline">{post.title} Biography, Age / Favorite things, Family, Relationship & More</h1>
                                    <div className="single-meta yes-wrap is-meta-author-color yes-border">
                                        <div className="smeta-in"> <a className="meta-el meta-avatar">
                                            <img src={Profileimg} width="120" height="120" className="avatar avatar-120 wp-user-avatar wp-user-avatar-120 alignnone photo" alt={post.title} loading="lazy" /></a>
                                            <div className="smeta-sec">
                                                <div className="smeta-bottom meta-text"> <time className="updated-date" datetime={post.date}>Last updated: {moment(post.date).format("MMMM D, YYYY")}</time> </div>
                                                <div className="p-meta">
                                                    <div className="meta-inner is-meta"><span className="meta-el meta-author"> <span className="meta-label">By</span> <a>Admin</a> </span> </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="smeta-extra">
                                            <div className="t-shared-sec tooltips-n is-color">
                                                <div className="t-shared-header is-meta"> <i className="rbi rbi-share" aria-hidden="true"></i><span className="share-label">Share</span> </div>
                                                <ShareKit url={postUrl} title={post.title} />
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <div className="grid-container">
                                    <div className="s-ct">
                                        <div className="s-feat-outer">
                                            <div className="s-feat">
                                                <div className="featured-lightbox-trigger" data-source="wp-content/uploads/2021/11/Mayim-Bialik.jpg" data-caption data-attribution> <img loading="lazy" width="860" height="552" src={`${SERVER_IMG_PATH}/${post.featuredImage}`} className="attachment-foxiz_crop_o1 size-foxiz_crop_o1 wp-post-image" alt={post.title} decoding="async" srcset={`${SERVER_IMG_PATH}/${post.featuredImage} 654w`} sizes="(max-width: 860px) 100vw, 860px" /> </div>
                                            </div>
                                        </div>
                                        <div className="s-ct-wrap has-lsl">
                                            <div className="s-ct-inner">
                                                <div className="l-shared-sec-outer show-mobile">
                                                    <SideShareKit url={postUrl} title={post.title} />
                                                </div>
                                                <div className="e-ct-outer">
                                                    {/* Section 1: Biography */}
                                                    {!isSectionEmpty([post.content, post.shortBio1]) && (
                                                        <>
                                                        <h2 id="biography-wiki">Biography / Wiki</h2>
                                                        {post.content && <p>{post.content}</p>}
                                                        <div class="ruby-table-contents rbtoc table-fw"><div class="toc-header"><i class="rbi rbi-read"></i><span class="h3">Contents</span><div class="toc-toggle no-link"><i class="rbi rbi-angle-up"></i></div></div><div class="toc-content">
                                                            <a href="#biography-wiki" class="table-link no-link anchor-link h5" data-index="rb-heading-index-0">Biography / Wiki</a>
                                                            {!isSectionEmpty([
                                                                post.childhoodExperiences,
                                                                post.personalityDevelopment,
                                                                post.earlyChallenges,
                                                            ]) && (
                                                            <a href="#early-life" class="table-link no-link anchor-link h5" data-index="rb-heading-index-1">Early Life</a>
                                                            )}
                                                            {!isSectionEmpty([
                                                                post.age,
                                                                post.height,
                                                                post.weight,
                                                                post.color,
                                                                post.favoriteActors,
                                                                post.favoriteActresses,
                                                                post.favoriteSingers,
                                                                post.favoriteSports,
                                                                post.favoriteFood,
                                                                post.favoriteDestination,
                                                                post.favoriteBooks,
                                                                post.favoriteMusic,
                                                                post.favoriteMovies,
                                                                post.favoriteHobbies,
                                                                post.favoriteTVShows,
                                                                post.favoriteGames,
                                                            ]) && (
                                                            <a href="#age-height-favorite" class="table-link no-link anchor-link h5" data-index="rb-heading-index-2">Age / Height / Favorite Things</a>
                                                            )}
                                                            {!isSectionEmpty([
                                                                post.schoolEducation,
                                                                post.schoolYears,
                                                                post.collegeEducation,
                                                                post.collegeYears,
                                                                post.postgraduateEducation,
                                                                post.postgraduateYears,
                                                                post.additionalEducation,
                                                                post.additionalYears,
                                                            ]) && (
                                                            <a href="#educational-career" class="table-link no-link anchor-link h5" data-index="rb-heading-index-3">Educational Career</a>)}
                                                            {!isSectionEmpty([
                                                                post.netWorth,
                                                                post.companyName,
                                                                post.industry,
                                                                post.initialVision,
                                                                post.majorMilestones,
                                                                post.challenges,
                                                                post.innovations,
                                                                post.businessGrowth,
                                                                post.keyProducts,
                                                                post.impact,
                                                                post.futureGoals,
                                                                post.currentPosition,
                                                                post.keyResponsibilities,
                                                                post.strategicDecisions,
                                                                post.impactOnGrowth,
                                                                post.leadershipStyle,
                                                                post.mentorship,
                                                                post.majorProjects,
                                                                post.businessprofessionalNetworks,
                                                                post.challengesFaced,
                                                                post.supportNetworks,
                                                                post.growthStrategies,
                                                                post.impactOnCommunity,
                                                                post.businessVentures,
                                                                post.brandCollaborations,
                                                                post.productLines,
                                                                post.investmentPortfolio,
                                                                post.roleInBusiness,
                                                                post.impactOnBrand,
                                                                post.philanthropicActivities,
                                                                post.politicalPosition,
                                                                post.businessInterests,
                                                                post.economicPolicies,
                                                                post.impactOnBusiness,
                                                                post.keyDecisions,
                                                                post.publicCollaborations,
                                                                post.projectsInitiatives,
                                                                post.professionalNetworks,
                                                                ]) && (
                                                            <a href="#business-career" class="table-link no-link anchor-link h5" data-index="rb-heading-index-4">Business Career</a>)}
                                                            {!isSectionEmpty([
                                                                post.dateOfBirth,
                                                                post.placeOfBirth,
                                                                post.zodiacSign,
                                                                post.nationality,
                                                                post.homeTown,
                                                                post.languages,
                                                                post.familyBackground,
                                                                post.culturalHeritage,
                                                                post.historicalEvents,
                                                                post.significantLifeEvents,
                                                                post.earlyInfluences,
                                                                post.religiousBeliefs,
                                                                post.personalMilestones,
                                                            ]) && (
                                                            <a href="#personal-life" class="table-link no-link anchor-link h5" data-index="rb-heading-index-5">Personal Life</a>)}
                                                            {!isSectionEmpty([
                                                                post.maritalStatus,
                                                                post.gender,
                                                                post.spouseName,
                                                                post.fatherName,
                                                                post.motherName,
                                                                post.children,
                                                                post.brothers,
                                                                post.sisters,
                                                                post.girlfriends,
                                                                post.boyfriends,
                                                                post.relatives,
                                                                post.additionalInfo,
                                                            ]) && (
                                                            <a href="#family-relationship" class="table-link no-link anchor-link h5" data-index="rb-heading-index-6">Family / Relationship</a>)}
                                                            {!isSectionEmpty([
                                                                post.career,
                                                                post.keyIssues,
                                                                post.policyProposals,
                                                                post.campaignAnnouncements,
                                                                post.eventsAndRallies,
                                                                post.volunteerAndDonationInfo,
                                                                post.pressReleases,
                                                                post.mediaCoverage,
                                                                post.socialMediaUpdates,
                                                                post.positionPapers,
                                                                post.opinionArticles,
                                                                post.speechesAndDebates,
                                                                post.interviewsAndQASessions,
                                                                post.campaignAds,
                                                                post.supporterTestimonials,
                                                                post.endorsements,
                                                                post.constituentServices,
                                                                post.feedbackAndSurveys,
                                                                post.communityInitiatives,
                                                            ]) && (
                                                            <a href="#political-career" class="table-link no-link anchor-link h5" data-index="rb-heading-index-7">Political Career and More</a>)}
                                                            {!isSectionEmpty([
                                                                post.industryAwards,
                                                                post.leadershipAwards,
                                                                post.innovationAwards,
                                                                post.communityImpact,
                                                                post.publicRecognition,
                                                                post.philanthropicAchievements,
                                                                post.careerMilestones,
                                                            ]) && (
                                                            <a href="#awards-and-achievements" class="table-link no-link anchor-link h5" data-index="rb-heading-index-8">Awards and Achievements</a>)}
                                                            {!isSectionEmpty([
                                                                post.uniqueAchievements,
                                                                post.personalInsights,
                                                                post.culturalImpact,
                                                                post.historicalSignificance,
                                                            ]) && (
                                                            <a href="#some-fnteresting-facts" class="table-link no-link anchor-link h5" data-index="rb-heading-index-10">Some Interesting Facts About {post.title}</a>)}
                                                            {!isSectionEmpty([
                                                                post.instagram,
                                                                post.twitter,
                                                                post.facebook,
                                                                post.linkedin,
                                                            ]) && (
                                                            <a href="#social-media-profiles" class="table-link no-link anchor-link h5" data-index="rb-heading-index-11">Social Media Profile(s)</a>)}
                                                        </div></div>
                                                        {post.shortBio1 && <p>{post.shortBio1}</p>}
                                                        {post.shortBio2 && <p>{post.shortBio2}</p>}
                                                        </>
                                                    )}
                                                    {/* Section 2: Early Life */}
                                                    {!isSectionEmpty([
                                                        post.childhoodExperiences,
                                                        post.personalityDevelopment,
                                                        post.earlyChallenges,
                                                    ]) && (
                                                        <>
                                                        <h2 id="early-life">Early Life</h2>
                                                        {post.childhoodExperiences && <p>{post.childhoodExperiences}</p>}
                                                        {post.personalityDevelopment && <p>{post.personalityDevelopment}</p>}
                                                        {post.earlyChallenges && <p>{post.earlyChallenges}</p>}
                                                        </>
                                                    )}
                                                    {/* Section 3: Age / Height / Favorite Things */}
                                                    {!isSectionEmpty([
                                                        post.age,
                                                        post.height,
                                                        post.weight,
                                                        post.color,
                                                        post.favoriteActors,
                                                        post.favoriteActresses,
                                                        post.favoriteSingers,
                                                        post.favoriteSports,
                                                        post.favoriteFood,
                                                        post.favoriteDestination,
                                                        post.favoriteBooks,
                                                        post.favoriteMusic,
                                                        post.favoriteMovies,
                                                        post.favoriteHobbies,
                                                        post.favoriteTVShows,
                                                        post.favoriteGames,
                                                    ]) && (
                                                        <>
                                                        <h2 id="age-height-favorite">Age / Height / Favorite Things</h2>
                                                        <table width="407">
                                                            <tbody>
                                                                {post.age && <tr><td>Age</td><td> {post.age}</td></tr>}
                                                                {post.height && <tr><td>Height</td><td> {post.height}</td></tr>}
                                                                {post.weight && <tr><td>Weight</td><td> {post.weight}</td></tr>}
                                                                {post.color && <tr><td>Favorite Color</td><td> {post.color}</td></tr>}
                                                                {post.favoriteActors && <tr><td>Favorite Actors</td><td> {post.favoriteActors}</td></tr>}
                                                                {post.favoriteActresses && <tr><td>Favorite Actresses</td><td> {post.favoriteActresses}</td></tr>}
                                                                {post.favoriteSingers && <tr><td>Favorite Singers</td><td> {post.favoriteSingers}</td></tr>}
                                                                {post.favoriteSports && <tr><td>Favorite Sports</td><td> {post.favoriteSports}</td></tr>}
                                                                {post.favoriteFood && <tr><td>Favorite Food</td><td> {post.favoriteFood}</td></tr>}
                                                                {post.favoriteDestination && <tr><td>Favorite Destination</td><td> {post.favoriteDestination}</td></tr>}
                                                                {post.favoriteBooks && <tr><td>Favorite Books</td><td> {post.favoriteBooks}</td></tr>}
                                                                {post.favoriteMusic && <tr><td>Favorite Music</td><td> {post.favoriteMusic}</td></tr>}
                                                                {post.favoriteMovies && <tr><td>Favorite Movies</td><td> {post.favoriteMovies}</td></tr>}
                                                                {post.favoriteHobbies && <tr><td>Favorite Hobbies</td><td> {post.favoriteHobbies}</td></tr>}
                                                                {post.favoriteTVShows && <tr><td>Favorite TV Shows</td><td> {post.favoriteTVShows}</td></tr>}
                                                                {post.favoriteGames && <tr><td>Favorite Games</td><td> {post.favoriteGames}</td></tr>}
                                                            </tbody>
                                                        </table>
                                                        </>
                                                    )}
                                                    {/* Section 4: Educational Career */}
                                                    {!isSectionEmpty([
                                                        post.schoolEducation,
                                                        post.schoolYears,
                                                        post.collegeEducation,
                                                        post.collegeYears,
                                                        post.postgraduateEducation,
                                                        post.postgraduateYears,
                                                        post.additionalEducation,
                                                        post.additionalYears,
                                                    ]) && (
                                                        <>
                                                        <h2 id="educational-career">Educational Career</h2>
                                                        <table width="407">
                                                            <tbody>
                                                                {post.schoolEducation && <tr><td>School Education</td><td> {post.schoolEducation}</td></tr>}
                                                                {post.schoolYears && <tr><td>School Years</td><td> {post.schoolYears}</td></tr>}
                                                                {post.collegeEducation && <tr><td>College Education</td><td> {post.collegeEducation}</td></tr>}
                                                                {post.collegeYears && <tr><td>College Years</td><td> {post.collegeYears}</td></tr>}
                                                                {post.postgraduateEducation && <tr><td>Postgraduate Education</td><td> {post.postgraduateEducation}</td></tr>}
                                                                {post.postgraduateYears && <tr><td>Postgraduate Years</td><td> {post.postgraduateYears}</td></tr>}
                                                                {post.additionalEducation && <tr><td>Additional Education</td><td> {post.additionalEducation}</td></tr>}
                                                                {post.additionalYears && <tr><td>Additional Years</td><td> {post.additionalYears}</td></tr>}
                                                            </tbody>
                                                        </table>
                                                        </>
                                                    )}
                                                    {/* Section 5: Business Career */}
                                                    {!isSectionEmpty([
                                                    post.netWorth,
                                                    post.companyName,
                                                    post.industry,
                                                    post.initialVision,
                                                    post.majorMilestones,
                                                    post.challenges,
                                                    post.innovations,
                                                    post.businessGrowth,
                                                    post.keyProducts,
                                                    post.impact,
                                                    post.futureGoals,
                                                    post.currentPosition,
                                                    post.keyResponsibilities,
                                                    post.strategicDecisions,
                                                    post.impactOnGrowth,
                                                    post.leadershipStyle,
                                                    post.mentorship,
                                                    post.majorProjects,
                                                    post.businessprofessionalNetworks,
                                                    post.challengesFaced,
                                                    post.supportNetworks,
                                                    post.growthStrategies,
                                                    post.impactOnCommunity,
                                                    post.businessVentures,
                                                    post.brandCollaborations,
                                                    post.productLines,
                                                    post.investmentPortfolio,
                                                    post.roleInBusiness,
                                                    post.impactOnBrand,
                                                    post.philanthropicActivities,
                                                    post.politicalPosition,
                                                    post.businessInterests,
                                                    post.economicPolicies,
                                                    post.impactOnBusiness,
                                                    post.keyDecisions,
                                                    post.publicCollaborations,
                                                    post.projectsInitiatives,
                                                    post.professionalNetworks,
                                                    ]) && (
                                                    <>
                                                        <h2 id="business-career">Business Career</h2>
                                                        {!isSectionEmpty([
                                                            post.netWorth,
                                                            post.companyName,
                                                            post.industry,
                                                            post.currentPosition,
                                                            post.politicalPosition,
                                                        ]) && (
                                                            <>
                                                            <table width="407">
                                                                <tbody>
                                                                    {post.netWorth && <tr><td><b>Net Worth</b></td><td><b>{post.netWorth}</b></td></tr>}
                                                                    {post.companyName && <tr><td>Company Name</td><td> {post.companyName}</td></tr>}
                                                                    {post.industry && <tr><td>Industry</td><td> {post.industry}</td></tr>}
                                                                    {post.currentPosition && <tr><td>Current Position</td><td> {post.currentPosition}</td></tr>}
                                                                    {post.politicalPosition && <tr><td>Political Position</td><td> {post.politicalPosition}</td></tr>}
                                                                </tbody>
                                                            </table>
                                                            </>
                                                        )}
                                                        {post.initialVision && <p><bold>Initial Vision:</bold> {post.initialVision}</p>}
                                                        {post.majorMilestones && <p><bold>Major Milestones:</bold> {post.majorMilestones}</p>}
                                                        {post.challenges && <p><bold>Challenges:</bold> {post.challenges}</p>}
                                                        {post.innovations && <p><bold>Innovations:</bold> {post.innovations}</p>}
                                                        {post.businessGrowth && <p><bold>Business Growth:</bold> {post.businessGrowth}</p>}
                                                        {post.keyProducts && <p><bold>Key Products:</bold> {post.keyProducts}</p>}
                                                        {post.impact && <p><bold>Impact:</bold> {post.impact}</p>}
                                                        {post.futureGoals && <p><bold>Future Goals:</bold> {post.futureGoals}</p>}
                                                        {post.keyResponsibilities && <p><bold>Key Responsibilities:</bold> {post.keyResponsibilities}</p>}
                                                        {post.strategicDecisions && <p><bold>Strategic Decisions:</bold> {post.strategicDecisions}</p>}
                                                        {post.impactOnGrowth && <p><bold>Impact on Growth:</bold> {post.impactOnGrowth}</p>}
                                                        {post.leadershipStyle && <p><bold>Leadership Style:</bold> {post.leadershipStyle}</p>}
                                                        {post.mentorship && <p><bold>Mentorship:</bold> {post.mentorship}</p>}
                                                        {post.majorProjects && <p><bold>Major Projects:</bold> {post.majorProjects}</p>}
                                                        {post.businessprofessionalNetworks && <p><bold>Business Professional Networks:</bold> {post.businessprofessionalNetworks}</p>}
                                                        {post.challengesFaced && <p><bold>Challenges Faced:</bold> {post.challengesFaced}</p>}
                                                        {post.supportNetworks && <p><bold>Support Networks:</bold> {post.supportNetworks}</p>}
                                                        {post.growthStrategies && <p><bold>Growth Strategies:</bold> {post.growthStrategies}</p>}
                                                        {post.impactOnCommunity && <p><bold>Impact on Community:</bold> {post.impactOnCommunity}</p>}
                                                        {post.businessVentures && <p><bold>Business Ventures:</bold> {post.businessVentures}</p>}
                                                        {post.brandCollaborations && <p><bold>Brand Collaborations:</bold> {post.brandCollaborations}</p>}
                                                        {post.productLines && <p><bold>Product Lines:</bold> {post.productLines}</p>}
                                                        {post.investmentPortfolio && <p><bold>Investment Portfolio:</bold> {post.investmentPortfolio}</p>}
                                                        {post.roleInBusiness && <p><bold>Role in Business:</bold> {post.roleInBusiness}</p>}
                                                        {post.impactOnBrand && <p><bold>Impact on Brand:</bold> {post.impactOnBrand}</p>}
                                                        {post.philanthropicActivities && <p><bold>Philanthropic Activities:</bold> {post.philanthropicActivities}</p>}
                                                        {post.businessInterests && <p><bold>Business Interests:</bold> {post.businessInterests}</p>}
                                                        {post.economicPolicies && <p><bold>Economic Policies:</bold> {post.economicPolicies}</p>}
                                                        {post.impactOnBusiness && <p><bold>Impact on Business:</bold> {post.impactOnBusiness}</p>}
                                                        {post.keyDecisions && <p><bold>Key Decisions:</bold> {post.keyDecisions}</p>}
                                                        {post.publicCollaborations && <p><bold>Public Collaborations:</bold> {post.publicCollaborations}</p>}
                                                        {post.projectsInitiatives && <p><bold>Projects Initiatives:</bold> {post.projectsInitiatives}</p>}
                                                        {post.professionalNetworks && <p><bold>Professional Networks:</bold> {post.professionalNetworks}</p>}
                                                    </>
                                                    )}
                                                    {/* Section 6: Personal Life */}
                                                    {!isSectionEmpty([
                                                        post.dateOfBirth,
                                                        post.placeOfBirth,
                                                        post.zodiacSign,
                                                        post.nationality,
                                                        post.homeTown,
                                                        post.languages,
                                                        post.familyBackground,
                                                        post.culturalHeritage,
                                                        post.historicalEvents,
                                                        post.significantLifeEvents,
                                                        post.earlyInfluences,
                                                        post.religiousBeliefs,
                                                        post.personalMilestones,
                                                    ]) && (
                                                        <>
                                                        <h2 id="personal-life">Personal Life</h2>
                                                        
                                                        <table width="407">
                                                            <tbody>
                                                                {post.dateOfBirth && <tr><td>Date of Birth</td><td> {formatDate(post.dateOfBirth)}</td></tr>}
                                                                {post.placeOfBirth && <tr><td>Place of Birth</td><td> {post.placeOfBirth}</td></tr>}
                                                                {post.zodiacSign && <tr><td>Zodiac Sign</td><td> {post.zodiacSign}</td></tr>}
                                                                {post.nationality && <tr><td>Nationality</td><td> {post.nationality}</td></tr>}
                                                                {post.homeTown && <tr><td>Home Town</td><td> {post.homeTown}</td></tr>}
                                                                {post.languages && <tr><td>Languages</td><td> {post.languages}</td></tr>}
                                                            </tbody>
                                                        </table>
                                                        {post.familyBackground && <p>Family Background: {post.familyBackground}</p>}
                                                        {post.culturalHeritage && <p>Cultural Heritage: {post.culturalHeritage}</p>}
                                                        {post.historicalEvents && <p>Historical Events: {post.historicalEvents}</p>}
                                                        {post.significantLifeEvents && <p>Significant Life Events: {post.significantLifeEvents}</p>}
                                                        {post.earlyInfluences && <p>Early Influences: {post.earlyInfluences}</p>}
                                                        {post.religiousBeliefs && <p>Religious Beliefs: {post.religiousBeliefs}</p>}
                                                        {post.personalMilestones && <p>Personal Milestones: {post.personalMilestones}</p>}
                                                        
                                                        {post.featuredImage1 && <img loading="lazy" width="560" height="452" src={`${SERVER_IMG_PATH}/${post.featuredImage1}`} className="attachment-foxiz_crop_o1 size-foxiz_crop_o1 wp-post-image" alt={post.title} decoding="async" srcset={`${SERVER_IMG_PATH}/${post.featuredImage1} 654w`} sizes="(max-width: 560px) 100vw, 560px" />}
                                                        </>
                                                    )}
                                                    {/* Section 7: Family / Relationship */}
                                                    {!isSectionEmpty([
                                                        post.maritalStatus,
                                                        post.gender,
                                                        post.spouseName,
                                                        post.spousePhoto,
                                                        post.fatherName,
                                                        post.fatherPhoto,
                                                        post.motherName,
                                                        post.motherPhoto,
                                                        post.children,
                                                        post.brothers,
                                                        post.sisters,
                                                        post.girlfriends,
                                                        post.boyfriends,
                                                        post.relatives,
                                                        post.additionalInfo,
                                                    ]) && (
                                                        <>
                                                        <h2 id="family-relationship">Family / Relationship</h2>
                                                        <table>
                                                        <tbody>
                                                            {post.maritalStatus && <tr>
                                                                <td>Marital Status</td>
                                                                <td> {post.maritalStatus}</td>
                                                            </tr>}
                                                            {post.gender && <tr>
                                                                <td>Gender</td>
                                                                <td> {post.gender}</td>
                                                            </tr>}
                                                            {post.spouseName && <tr>
                                                                <td>Spouse Name</td>
                                                                <td> 
                                                                    {post.spouseName}<br/>
                                                                    {post.spousePhoto && (
                                                                    <figure><img src={post.spousePhoto} alt={post.spouseName} style={{ width: '120px', height: '150px' }} loading="lazy" />
                                                                    <figcaption>{post.spouseName}</figcaption>
                                                                    </figure>
                                                                    )}
                                                                </td>
                                                            </tr>}
                                                            {post.fatherName && <tr>
                                                                <td>Father's Name</td>
                                                                <td> 
                                                                    {post.fatherName}<br/>
                                                                    {post.fatherPhoto && (
                                                                    <figure><img src={post.fatherPhoto} alt={post.fatherName} style={{ width: '120px', height: '150px' }} loading="lazy" />
                                                                    <figcaption>{post.fatherName}</figcaption>
                                                                    </figure>
                                                                    )}
                                                                </td>
                                                            </tr>}
                                                            {post.motherName && <tr>
                                                                <td>Mother's Name</td>
                                                                <td> {post.motherName}<br/>
                                                                    {post.motherPhoto && (
                                                                    <figure><img src={post.motherPhoto} alt={post.motherName} style={{ width: '120px', height: '150px' }} loading="lazy" />
                                                                    <figcaption>{post.motherName}</figcaption>
                                                                    </figure>
                                                                    )}
                                                                </td>
                                                            </tr>}
                                                            {post.children.length > 0 && post.children.some(child => child.name || child.photo) && (
                                                                <tr>
                                                                    <td>Children</td>
                                                                    <td>
                                                                        {post.children.map((child, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {child.name && <>{child.name}<br /></>}
                                                                                {child.photo && (
                                                                                    <figure>
                                                                                        <img src={child.photo} alt={child.name} style={{ width: '120px', height: '150px' }} loading="lazy" />
                                                                                        <figcaption>{child.name}</figcaption>
                                                                                    </figure>
                                                                                )}
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {post.brothers.length > 0 && post.brothers.some(brother => brother.name || brother.photo) && (
                                                                <tr>
                                                                    <td>Brothers</td>
                                                                    <td>
                                                                        {post.brothers.map((brother, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {brother.name && <>{brother.name}<br /></>}
                                                                                {brother.photo && (
                                                                                    <figure>
                                                                                        <img src={brother.photo} alt={brother.name} style={{ width: '120px', height: '150px' }} loading="lazy" />
                                                                                        <figcaption>{brother.name}</figcaption>
                                                                                    </figure>
                                                                                )}
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {post.sisters.length > 0 && post.sisters.some(sister => sister.name || sister.photo) && (
                                                                <tr>
                                                                    <td>Sisters</td>
                                                                    <td>
                                                                        {post.sisters.map((sister, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {sister.name && <>{sister.name}<br /></>}
                                                                                {sister.photo && (
                                                                                    <figure>
                                                                                        <img src={sister.photo} alt={sister.name} style={{ width: '120px', height: '150px' }} loading="lazy" />
                                                                                        <figcaption>{sister.name}</figcaption>
                                                                                    </figure>
                                                                                )}
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {post.girlfriends.length > 0 && post.girlfriends.some(girlfriend => girlfriend.name || girlfriend.photo) && (
                                                                <tr>
                                                                    <td>Girlfriends</td>
                                                                    <td>
                                                                        {post.girlfriends.map((girlfriend, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {girlfriend.name && <>{girlfriend.name}<br /></>}
                                                                                {girlfriend.photo && (
                                                                                    <figure>
                                                                                        <img src={girlfriend.photo} alt={girlfriend.name} style={{ width: '120px', height: '150px' }} loading="lazy" />
                                                                                        <figcaption>{girlfriend.name}</figcaption>
                                                                                    </figure>
                                                                                )}
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {Array.isArray(post.boyfriends) && post.boyfriends.some(boyfriend => boyfriend.name || boyfriend.photo) && (
                                                                <tr>
                                                                    <td>Boyfriends</td>
                                                                    <td> 
                                                                        {post.boyfriends.map((boyfriend, index) => (
                                                                            (boyfriend.name || boyfriend.photo) && (
                                                                                <div key={index}>
                                                                                    {boyfriend.name && <>{boyfriend.name}<br/></>}
                                                                                    {boyfriend.photo && (
                                                                                        <figure>
                                                                                            <img src={boyfriend.photo} alt={boyfriend.name} style={{ width: '120px', height: '150px' }} loading="lazy" />
                                                                                            <figcaption>{boyfriend.name}</figcaption>
                                                                                        </figure>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {post.relatives.length > 0 && post.relatives.some(relative => relative.name || relative.photo) && (
                                                                <tr>
                                                                    <td>Relatives</td>
                                                                    <td>
                                                                        {post.relatives.map((relative, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {relative.name && <>{relative.name}<br /></>}
                                                                                {relative.photo && (
                                                                                    <figure>
                                                                                        <img src={relative.photo} alt={relative.name} style={{ width: '120px', height: '150px' }} loading="lazy" />
                                                                                        <figcaption>{relative.name}</figcaption>
                                                                                    </figure>
                                                                                )}
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                        </table>
                                                        {post.additionalInfo && <p>{post.additionalInfo}</p>}
                                                        </>
                                                    )}

                                                    {/* Section 8: Political Career */}
                                                    {!isSectionEmpty([
                                                        post.career,
                                                        post.keyIssues,
                                                        post.policyProposals,
                                                        post.campaignAnnouncements,
                                                        post.eventsAndRallies,
                                                        post.volunteerAndDonationInfo,
                                                        post.pressReleases,
                                                        post.mediaCoverage,
                                                        post.socialMediaUpdates,
                                                        post.positionPapers,
                                                        post.opinionArticles,
                                                        post.speechesAndDebates,
                                                        post.interviewsAndQASessions,
                                                        post.campaignAds,
                                                        post.supporterTestimonials,
                                                        post.endorsements,
                                                        post.constituentServices,
                                                        post.feedbackAndSurveys,
                                                        post.communityInitiatives,
                                                    ]) && (
                                                        <>
                                                        <h2 id="political-career">Political Career</h2>
                                                        {post.career && <p>{post.career}</p>}
                                                        {post.keyIssues && <p>{post.keyIssues}</p>}
                                                        {post.policyProposals && <p>{post.policyProposals}</p>}
                                                        {post.campaignAnnouncements && <p>{post.campaignAnnouncements}</p>}
                                                        {post.eventsAndRallies && <p>{post.eventsAndRallies}</p>}
                                                        {post.volunteerAndDonationInfo && <p>{post.volunteerAndDonationInfo}</p>}
                                                        {post.pressReleases && <p>{post.pressReleases}</p>}
                                                        {post.mediaCoverage && <p>{post.mediaCoverage}</p>}
                                                        {post.socialMediaUpdates && <p>{post.socialMediaUpdates}</p>}
                                                        {post.positionPapers && <p>{post.positionPapers}</p>}
                                                        {post.opinionArticles && <p>{post.opinionArticles}</p>}
                                                        {post.speechesAndDebates && <p>{post.speechesAndDebates}</p>}
                                                        {post.interviewsAndQASessions && <p>{post.interviewsAndQASessions}</p>}
                                                        {post.campaignAds && <p>{post.campaignAds}</p>}
                                                        {post.supporterTestimonials && <p>{post.supporterTestimonials}</p>}
                                                        {post.endorsements && <p>{post.endorsements}</p>}
                                                        {post.constituentServices && <p>{post.constituentServices}</p>}
                                                        {post.feedbackAndSurveys && <p>{post.feedbackAndSurveys}</p>}
                                                        {post.communityInitiatives && <p>{post.communityInitiatives}</p>}
                                                        </>
                                                    )}

                                                    {/* Section 9: Awards and Achievements */}
                                                    {!isSectionEmpty([
                                                        post.industryAwards,
                                                        post.leadershipAwards,
                                                        post.innovationAwards,
                                                        post.communityImpact,
                                                        post.publicRecognition,
                                                        post.philanthropicAchievements,
                                                        post.careerMilestones,
                                                    ]) && (
                                                        <>
                                                        <h2 id="awards-and-achievements">Awards and Achievements</h2>
                                                        <ul className="listmarginleft">
                                                        {post.industryAwards && <li>{post.industryAwards}</li>}
                                                        {post.leadershipAwards && <li>{post.leadershipAwards}</li>}
                                                        {post.innovationAwards && <li>{post.innovationAwards}</li>}
                                                        {post.communityImpact && <li>{post.communityImpact}</li>}
                                                        {post.publicRecognition && <li>{post.publicRecognition}</li>}
                                                        {post.philanthropicAchievements && <li>{post.philanthropicAchievements}</li>}
                                                        {post.careerMilestones && <li>{post.careerMilestones}</li>}
                                                        </ul>
                                                        </>
                                                    )}

                                                    {/* Section 10: Some Interesting Facts */}
                                                    {!isSectionEmpty([
                                                        post.uniqueAchievements,
                                                        post.personalInsights,
                                                        post.culturalImpact,
                                                        post.historicalSignificance,
                                                    ]) && (
                                                        <>
                                                        <h2 id="some-fnteresting-facts">Some Interesting Facts</h2>
                                                        <ul className="listmarginleft">
                                                        {post.uniqueAchievements && <li>{post.uniqueAchievements}</li>}
                                                        {post.personalInsights && <li>{post.personalInsights}</li>}
                                                        {post.culturalImpact && <li>{post.culturalImpact}</li>}
                                                        {post.historicalSignificance && <li>{post.historicalSignificance}</li>}
                                                        </ul>
                                                        </>
                                                    )}

                                                    {/* Section 11: Social Media Profiles */}
                                                    {!isSectionEmpty([
                                                        post.instagram,
                                                        post.twitter,
                                                        post.facebook,
                                                        post.linkedin,
                                                    ]) && (
                                                        <>
                                                        <h2 id="social-media-profiles">Social Media Profiles</h2>
                                                        <ul className="listmarginleft"><ol>
                                                        {post.instagram && <li><strong>Instagram: <a className="link" href={post.instagram}>@{post.title}</a></strong></li>}
                                                        {post.twitter && <li><strong>Twitter: <a className="link" href={post.twitter}>@{post.title}</a></strong></li>}
                                                        {post.facebook && <li><strong>Facebook: <a className="link" href={post.facebook}>@{post.title}</a></strong></li>}
                                                        {post.linkedin && <li><strong>LinkedIn: <a className="link" href={post.linkedin}>@{post.title}</a></strong></li>}
                                                        </ol></ul>
                                                        </>
                                                    )}
                                                    {/* <ViewContent textcontent={content} extractedTexts={extractedTexts} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            ) : (
                <div class="site-wrap"> 
                    <div class="page404-wrap rb-container edge-padding">
                        <div class="page404-inner">
                            <div class="page404-featured"></div>
                            <h1 class="page-title">Something's wrong here...</h1>
                            <p class="page404-description">It looks like nothing was found at this location. The page you were looking for does not exist or was loading incorrectly.</p>
                            <div class="page404-btn-wrap">
                                <Link to="/" class="page404-btn is-btn">Return to Homepage</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

function ViewContent({ textcontent, extractedTexts }) {
    const removeHTMLTags = (inputString) => {
        // Remove HTML tags using a regular expression
        const withoutTags = inputString.replace(/<\/?[^>]+(>|$)/g, "");
        
        // Decode HTML entities
        const decodedString = withoutTags.replace(/&nbsp;/g, " ");
      
        // Replace multiple spaces with a single space
        return decodedString.replace(/\s+/g, " ").trim();
    };
    return (
      <div className="entry-content rbct clearfix is-highlight-shares">
        <div class="ruby-table-contents rbtoc table-fw">
            <div class="toc-header"><i class="rbi rbi-read"></i><span class="h3">Contents</span>
                <div class="toc-toggle no-link"><i class="rbi rbi-angle-up"></i></div>
            </div>
            <div class="toc-content">
                {extractedTexts.map((text, index) => (
                    <a href={`#heading-${index + 1}`} className="table-link no-link anchor-link h5" data-index={`rb-heading-index-${index + 1}`}>{removeHTMLTags(text)}</a>
                ))}
            </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textcontent) }}></div>
      </div>
    );
}

export default SinglePost;