import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import {useSelector} from "react-redux"
import InspiringLeaders from "../assets/images/inspiring-leaders-biography.png";
//import NavbarTopBio from '../components/NavbarTopBio'
import { SERVER_PATH, SERVER_IMG_PATH } from "../AppConstants";
//import MenuTime from '../components/MenuTime'
import '../assets/css/style.css';
import '../assets/css/index.css';

const MenuTime = React.lazy(() => import('../components/MenuTime'));
const NavbarTopBio = React.lazy(() => import('../components/NavbarTopBio'));

const Header = () => {
    const [categories, setCategories] = useState([]);
    const [groupedPosts, setGroupedPosts] = useState([]);
    const cats = useSelector((state)=> state.catsInfo.cats);
    const style = {
        '--divider-pattern-url': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1' stroke-linecap='square' stroke-miterlimit='10'%3E%3Cpath d='M0,6c6,0,0.9,11.1,6.9,11.1S18,6,24,6'/%3E%3C/svg%3E")`
    };
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
    const minScroll = 100;const buttonText = "Back to Top";const containerID = "back-top";
    const [isVisible, setIsVisible] = useState(false);
        
    useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY > minScroll) {
            setIsVisible(true);
            } else {
            setIsVisible(false);
            }
    };
        
    window.addEventListener('scroll', handleScroll);
        
    return () => {
              window.removeEventListener('scroll', handleScroll);
            };
    }, [minScroll]);
        
    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const darkModeID = 'RubyDarkMode';
  
    // State to track the current theme mode
    const [theme, setTheme] = useState('default');
    const [isDarkMode, setIsDarkMode] = useState(false);

    // Function to toggle the theme
    const toggleTheme = () => {
        const newTheme = theme === 'dark' ? 'default' : 'dark';
        setTheme(newTheme);
        localStorage.setItem(darkModeID, newTheme);
        document.body.setAttribute('data-theme', newTheme);
    };

    // Check the local storage to get the saved theme
    useEffect(() => {
        const savedTheme = navigator.cookieEnabled ? localStorage.getItem(darkModeID) || 'default' : 'default';
        setTheme(savedTheme);
        document.body.setAttribute('data-theme', savedTheme);
    }, []);

    const toggleDarkMode = () => {
        toggleTheme();
        setIsDarkMode(!isDarkMode);
    };
        
    const isMobileHideClass = window.innerWidth < 1024 && document.body.classList.contains
    useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await axios.get(`${SERVER_PATH}/catposts/topdata`);
            if (res.data) {
                const { categories, groupedPosts } = res.data;

                setCategories(categories);
                setGroupedPosts(groupedPosts);
              } else {
                console.error("Post not found");
                // Handle case where post is not found, like navigating to a "Not Found" page
              }
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
    },[]);
    return (
        <div>
            <div id="site-header" className="header-wrap rb-section header-set-1 header-2 header-wrapper style-shadow">
                <div id="navbar-outer" className="navbar-outer">
                    <div id="sticky-holder" className="sticky-holder">
                        <div className="navbar-wrap">
                            <div className="rb-container edge-padding">
                                <div className="navbar-inner">
                                    <div className="navbar-left">
                                        <div className="logo-wrap is-image-logo site-branding"> 
                                            <Link to="/" className="logo" title="Inspiring Leaders - Biography of Famous Celebrity and Trending Personality"> 
                                                <img fetchpriority="high" className="logo-default" data-mode="default" height="180" width="544" src={InspiringLeaders} alt="Inspiring Leaders - Biography of Famous Celebrity and Trending Personality" decoding="async" loading="lazy" />
                                                <img className="logo-dark" data-mode="dark" height="180" width="544" src={InspiringLeaders} alt="Inspiring Leaders - Biography of Famous Celebrity and Trending Personality" decoding="async" loading="lazy" />
                                                <img className="logo-transparent" height="180" width="544" src={InspiringLeaders} alt="Inspiring Leaders - Biography of Famous Celebrity and Trending Personality" decoding="async" loading="lazy" />
                                                <h1 className="logo-title hidden">Inspiring Leaders - Biography of Famous Celebrity and Trending Personality</h1>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="navbar-center">
                                        <nav id="site-navigation" className="main-menu-wrap" aria-label="main menu">
                                            <ul id="menu-header-menu" className="main-menu rb-menu large-menu" itemscope itemtype="https://www.schema.org/SiteNavigationElement">
                                                {
                                                    Object.keys(cats).map((key, i) => {
                                                        if (key !== '0') {
                                                            return navbarcategory(cats[key][0], cats[key][1],groupedPosts, key);
                                                        }
                                                    })
                                                    // Object.keys(cats).map((key, i) => {
                                                    //     if (key !== '0') {
                                                    //       return (
                                                    //         <Navbarcategory catId={cats[key][0]} catName={cats[key][1]} />
                                                    //       );
                                                    //     }
                                                    // })
                                                }
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="navbar-right">
                                        <div className="dark-mode-toggle-wrap">
                                            <div className={`dark-mode-toggle ${theme === 'dark' ? 'triggered' : ''}`} onClick={toggleTheme}>
                                                <span className="dark-mode-slide">
                                                {/* Dark mode icon */}
                                                <i className={`dark-mode-slide-btn mode-icon-dark ${theme === 'dark' ? 'activated' : ''}`} data-title="Switch to Light">
                                                    <svg className="svg-icon svg-mode-dark" aria-hidden="true" role="img" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fill="currentColor" d="M507.681,209.011c-1.297-6.991-7.324-12.111-14.433-12.262c-7.104-0.122-13.347,4.711-14.936,11.643
                                                    c-15.26,66.497-73.643,112.94-141.978,112.94c-80.321,0-145.667-65.346-145.667-145.666c0-68.335,46.443-126.718,112.942-141.976
                                                    c6.93-1.59,11.791-7.826,11.643-14.934c-0.149-7.108-5.269-13.136-12.259-14.434C287.546,1.454,271.735,0,256,0
                                                    C187.62,0,123.333,26.629,74.98,74.981C26.628,123.333,0,187.62,0,256s26.628,132.667,74.98,181.019
                                                    C123.333,485.371,187.62,512,256,512s132.667-26.629,181.02-74.981C485.372,388.667,512,324.38,512,256
                                                    C512,240.278,510.546,224.469,507.681,209.011z"></path>
                                                    </svg>
                                                </i>

                                                {/* Light mode icon */}
                                                <i className={`dark-mode-slide-btn mode-icon-default ${theme === 'default' ? 'activated' : ''}`} data-title="Switch to Dark">
                                                    <svg className="svg-icon svg-mode-light" aria-hidden="true" role="img" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232.447 232.447">
                                                    <path fill="currentColor" d="M116.211,194.8c-4.143,0-7.5,3.357-7.5,7.5v22.643c0,4.143,3.357,7.5,7.5,7.5s7.5-3.357,7.5-7.5V202.3
                                                    C123.711,198.157,120.354,194.8,116.211,194.8z"></path>
                                                    <path fill="currentColor" d="M116.211,37.645c4.143,0,7.5-3.357,7.5-7.5V7.505c0-4.143-3.357-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v22.641
                                                    C108.711,34.288,112.068,37.645,116.211,37.645z"></path>
                                                    <path fill="currentColor" d="M50.054,171.78l-16.016,16.008c-2.93,2.929-2.931,7.677-0.003,10.606c1.465,1.466,3.385,2.198,5.305,2.198
                                                    c1.919,0,3.838-0.731,5.302-2.195l16.016-16.008c2.93-2.929,2.931-7.677,0.003-10.606C57.731,168.852,52.982,168.851,50.054,171.78
                                                    z"></path>
                                                    <path fill="currentColor" d="M177.083,62.852c1.919,0,3.838-0.731,5.302-2.195L198.4,44.649c2.93-2.929,2.931-7.677,0.003-10.606
                                                    c-2.93-2.932-7.679-2.931-10.607-0.003l-16.016,16.008c-2.93,2.929-2.931,7.677-0.003,10.607
                                                    C173.243,62.12,175.163,62.852,177.083,62.852z"></path>
                                                    <path fill="currentColor" d="M37.645,116.224c0-4.143-3.357-7.5-7.5-7.5H7.5c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h22.645
                                                    C34.287,123.724,37.645,120.366,37.645,116.224z"></path>
                                                    <path fill="currentColor" d="M224.947,108.724h-22.652c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h22.652c4.143,0,7.5-3.357,7.5-7.5
                                                    S229.09,108.724,224.947,108.724z"></path>
                                                    <path fill="currentColor" d="M50.052,60.655c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196c2.93-2.929,2.93-7.678,0.001-10.606
                                                    L44.652,34.042c-2.93-2.93-7.679-2.929-10.606-0.001c-2.93,2.929-2.93,7.678-0.001,10.606L50.052,60.655z"></path><path fill="currentColor" d="M182.395,171.782c-2.93-2.929-7.679-2.93-10.606-0.001c-2.93,2.929-2.93,7.678-0.001,10.607l16.007,16.008
                                                    c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196c2.93-2.929,2.93-7.678,0.001-10.607L182.395,171.782z"></path>
                                                    <path fill="currentColor" d="M116.22,48.7c-37.232,0-67.523,30.291-67.523,67.523s30.291,67.523,67.523,67.523s67.522-30.291,67.522-67.523
                                                    S153.452,48.7,116.22,48.7z M116.22,168.747c-28.962,0-52.523-23.561-52.523-52.523S87.258,63.7,116.22,63.7
                                                    c28.961,0,52.522,23.562,52.522,52.523S145.181,168.747,116.22,168.747z"></path>
                                                    </svg>
                                                </i>
                                                </span>
                                            </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div id="header-mobile" className={`header-mobile ${menuOpen ? 'collapse-activated' : ''}`}>
                        <div className="header-mobile-wrap">
                            <div className="mbnav edge-padding">
                                <div className="navbar-left">
                                    <div className="logo-wrap mobile-logo-wrap is-image-logo site-branding"> 
                                        <Link to="/" className="logo" title="Inspiring Leaders - Biography of Famous Celebrity and Trending Personality"> 
                                            <img fetchpriority="high" className="logo-default" data-mode="default" height="180" width="544" src={InspiringLeaders} alt="Inspiring Leaders - Biography of Famous Celebrity and Trending Personality" decoding="async" loading="lazy" />
                                            <img className="logo-dark" data-mode="dark" height="180" width="544" src={InspiringLeaders} alt="Inspiring Leaders - Biography of Famous Celebrity and Trending Personality" decoding="async" loading="lazy" /><img className="logo-transparent" height="180" width="544" src={InspiringLeaders} alt="Inspiring Leaders - Biography of Famous Celebrity and Trending Personality" decoding="async" loading="lazy" />
                                            <h1 className="logo-title hidden">Inspiring Leaders - Biography of Famous Celebrity and Trending Personality</h1>
                                        </Link> </div>
                                </div>
                                <div className="navbar-right"> 
                                    {/* <a href="#" className="mobile-menu-trigger mobile-search-icon" aria-label="search"><i className="rbi rbi-search" aria-hidden="true"></i></a> */}
                                    <div className="dark-mode-toggle-wrap">
                                        <div className={`dark-mode-toggle ${isDarkMode ? 'triggered' : ''}`}>
                                            <span className="dark-mode-slide" onClick={toggleDarkMode}>
                                            {/* Dark Mode Icon */}
                                            <i className={`dark-mode-slide-btn mode-icon-dark ${isDarkMode ? 'activated' : ''}`} data-title="Switch to Light">
                                                <svg
                                                className="svg-icon svg-mode-dark"
                                                aria-hidden="true"
                                                role="img"
                                                focusable="false"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                >
                                                <path
                                                    fill="currentColor"
                                                    d="M507.681,209.011c-1.297-6.991-7.324-12.111-14.433-12.262c-7.104-0.122-13.347,4.711-14.936,11.643 c-15.26,66.497-73.643,112.94-141.978,112.94c-80.321,0-145.667-65.346-145.667-145.666c0-68.335,46.443-126.718,112.942-141.976 c6.93-1.59,11.791-7.826,11.643-14.934c-0.149-7.108-5.269-13.136-12.259-14.434C287.546,1.454,271.735,0,256,0 C187.62,0,123.333,26.629,74.98,74.981C26.628,123.333,0,187.62,0,256s26.628,132.667,74.98,181.019 C123.333,485.371,187.62,512,256,512s132.667-26.629,181.02-74.981C485.372,388.667,512,324.38,512,256 C512,240.278,510.546,224.469,507.681,209.011z"
                                                />
                                                </svg>
                                            </i>

                                            {/* Light Mode Icon */}
                                            <i className={`dark-mode-slide-btn mode-icon-default ${!isDarkMode ? 'activated' : ''}`} data-title="Switch to Dark">
                                                <svg
                                                className="svg-icon svg-mode-light"
                                                aria-hidden="true"
                                                role="img"
                                                focusable="false"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 232.447 232.447"
                                                >
                                                <path
                                                    fill="currentColor"
                                                    d="M116.211,194.8c-4.143,0-7.5,3.357-7.5,7.5v22.643c0,4.143,3.357,7.5,7.5,7.5s7.5-3.357,7.5-7.5V202.3 C123.711,198.157,120.354,194.8,116.211,194.8z"
                                                />
                                                <path
                                                    fill="currentColor"
                                                    d="M116.211,37.645c4.143,0,7.5-3.357,7.5-7.5V7.505c0-4.143-3.357-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v22.641 C108.711,34.288,112.068,37.645,116.211,37.645z"
                                                />
                                                <path
                                                    fill="currentColor"
                                                    d="M50.054,171.78l-16.016,16.008c-2.93,2.929-2.931,7.677-0.003,10.606c1.465,1.466,3.385,2.198,5.305,2.198 c1.919,0,3.838-0.731,5.302-2.195l16.016-16.008c2.93-2.929,2.931-7.677,0.003-10.606C57.731,168.852,52.982,168.851,50.054,171.78 z"
                                                />
                                                <path fill="currentColor" d="M177.083,62.852c1.919,0,3.838-0.731,5.302-2.195L198.4,44.649c2.93-2.929,2.931-7.677,0.003-10.606
                                                c-2.93-2.932-7.679-2.931-10.607-0.003l-16.016,16.008c-2.93,2.929-2.931,7.677-0.003,10.607
                                                C173.243,62.12,175.163,62.852,177.083,62.852z"></path>
                                                <path fill="currentColor" d="M37.645,116.224c0-4.143-3.357-7.5-7.5-7.5H7.5c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h22.645
                                                C34.287,123.724,37.645,120.366,37.645,116.224z"></path>
                                                <path fill="currentColor" d="M224.947,108.724h-22.652c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h22.652c4.143,0,7.5-3.357,7.5-7.5
                                                S229.09,108.724,224.947,108.724z"></path>
                                                <path fill="currentColor" d="M50.052,60.655c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196c2.93-2.929,2.93-7.678,0.001-10.606
                                                L44.652,34.042c-2.93-2.93-7.679-2.929-10.606-0.001c-2.93,2.929-2.93,7.678-0.001,10.606L50.052,60.655z"></path>
                                                <path fill="currentColor" d="M182.395,171.782c-2.93-2.929-7.679-2.93-10.606-0.001c-2.93,2.929-2.93,7.678-0.001,10.607l16.007,16.008
                                                c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196c2.93-2.929,2.93-7.678,0.001-10.607L182.395,171.782z"></path>
                                                <path fill="currentColor" d="M116.22,48.7c-37.232,0-67.523,30.291-67.523,67.523s30.291,67.523,67.523,67.523s67.522-30.291,67.522-67.523
                                                S153.452,48.7,116.22,48.7z M116.22,168.747c-28.962,0-52.523-23.561-52.523-52.523S87.258,63.7,116.22,63.7
                                                c28.961,0,52.522,23.562,52.522,52.523S145.181,168.747,116.22,168.747z"></path>
                                                </svg>
                                            </i>
                                            </span>
                                        </div>
                                        </div>
                                    <div className="mobile-toggle-wrap"> <a href="#" className="mobile-menu-trigger" aria-label="mobile trigger" onClick={toggleMenu}> <span className="burger-icon"><span></span><span></span><span></span></span> </a> </div>
                                </div>
                            </div>
                        </div>
                        <div  className="mobile-collapse">
                            <div className="collapse-holder">
                                <div className="collapse-inner">
                                    {/* <div className="mobile-search-form edge-padding">
                                        <div className="header-search-form is-form-layout"> <span className="h5">Search</span>
                                            <form method="get" action="index.html" className="rb-search-form" data-search="post" data-limit="0" data-follow="0">
                                                <div className="search-form-inner"> <span className="search-icon"><i className="rbi rbi-search" aria-hidden="true"></i></span> <span className="search-text"><input type="text" className="field" placeholder="Search Headlines, News..." value name="s" /></span> <span className="rb-search-submit"><input type="submit" value="Search" /><i className="rbi rbi-cright" aria-hidden="true"></i></span> </div>
                                            </form>
                                        </div>
                                    </div> */}
                                    <nav className="mobile-menu-wrap edge-padding"> </nav>
                                    <div className="collapse-template">
                                        <div data-elementor-type="wp-post" data-elementor-id="50418" className="elementor elementor-50418">
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-dede56b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="dede56b" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-custom">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b7798c4" data-id="b7798c4" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-23da2d8 elementor-widget-divider--separator-type-pattern elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="23da2d8" data-element_type="widget" data-widget_type="divider.default">
                                                            </div>
                                                            <div className="elementor-element elementor-element-29eb4ca yes-colorful elementor-widget elementor-widget-foxiz-social-follower" data-id="29eb4ca" data-element_type="widget" data-widget_type="foxiz-social-follower.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="socials-counter h6 is-style-15 is-gstyle-3 is-icon-color is-h-bg">
                                                                        <ul id="menu-primary-menu" className="primary-menu" >
                                                                        {
                                                                            Object.keys(cats).map((key, i) => {
                                                                                if (key !== '0') {
                                                                                    return (
                                                                                        <li id="menu-item-667" className="menu-item menu-item-type-post_type menu-item-object-page">
                                                                                        <Link onClick={toggleMenu} to={{
                                                                                                pathname: `/${cats[key][1].toLowerCase().replace(/\s+/g, '-')}`,
                                                                                                state: { catId: cats[key][0] }
                                                                                            }} >{cats[key][1]}</Link> </li>
                                                                                    );
                                                                                        return menubiolink(cats[key][0], cats[key][1]);
                                                                                }
                                                                                return null; // Return null for the '0' case to avoid rendering anything
                                                                            })
                                                                        }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-23da2d8 elementor-widget-divider--separator-type-pattern elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="23da2d8" data-element_type="widget" data-widget_type="divider.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="elementor-divider" style={style}> <span className="elementor-divider-separator"> </span> </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-0f36716 elementor-widget elementor-widget-foxiz-heading" data-id="0f36716" data-element_type="widget" data-widget_type="foxiz-heading.default">
                                                                <div className="elementor-widget-container">
                                                                    <div id="uid_0f36716" className="block-h heading-layout-19">
                                                                        <div className="heading-inner">
                                                                            <h3 className="heading-title"><span className='heading-title-mobile'>Top Stories</span></h3>
                                                                            <div className="heading-tagline h6"><span className="heading-tagline-label heading-title-mobile">Explore the latest updated biography!</span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-726d36a elementor-widget elementor-widget-foxiz-list-small-2" data-id="726d36a" data-element_type="widget" data-widget_type="foxiz-list-small-2.default">
                                                                <div className="elementor-widget-container">
                                                                    <div id="uid_726d36a" className="block-wrap block-small block-list block-list-small-2 rb-columns rb-col-1 is-feat-left meta-s-">
                                                                        <div className="block-inner">
                                                                            <NavbarTopBio />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-48fd622 elementor-widget elementor-widget-foxiz-heading" data-id="48fd622" data-element_type="widget" data-widget_type="foxiz-heading.default">
                                                                <div className="elementor-widget-container">
                                                                    <div id="uid_48fd622" className="block-h heading-layout-19">
                                                                        <div className="heading-inner">
                                                                            <h3 className="heading-title"><span className='heading-title-mobile'>Stay Connected</span></h3>
                                                                            <div className="heading-tagline h6"><span className="heading-tagline-label heading-title-mobile">Find us on socials</span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="elementor-element elementor-element-29eb4ca yes-colorful elementor-widget elementor-widget-foxiz-social-follower" data-id="29eb4ca" data-element_type="widget" data-widget_type="foxiz-social-follower.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="socials-counter h6 is-style-15 is-gstyle-3 is-icon-color is-h-bg">
                                                                        <div className="social-follower effect-fadeout">
                                                                            <div className="follower-el fb-follower"><a target="_blank"  className="facebook" aria-label="Facebook" rel="noopener nofollow" onClick={toggleMenu}></a><span className="follower-inner"><span className="fnicon"><i className="rbi rbi-facebook" aria-hidden="true"></i></span><span className="fntotal">248.1k</span><span className="fnlabel">Followers</span><span className="text-count">Like</span></span></div>
                                                                            <div className="follower-el twitter-follower"><a target="_blank" className="twitter" aria-label="Twitter" rel="noopener nofollow" onClick={toggleMenu}></a><span className="follower-inner"><span className="fnicon"><i className="rbi rbi-twitter" aria-hidden="true"></i></span><span className="fntotal">61.1k</span><span className="fnlabel">Followers</span><span className="text-count">Follow</span></span></div>
                                                                            <div className="follower-el youtube-follower"><a target="_blank" className="youtube" aria-label="Youtube" rel="noopener nofollow" onClick={toggleMenu}></a><span className="follower-inner"><span className="fnicon"><i className="rbi rbi-youtube" aria-hidden="true"></i></span><span className="fntotal">165k</span><span className="fnlabel">Subscribers</span><span className="text-count">Subscribe</span></span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div className="collapse-sections"> </div>
                                    <div className="collapse-footer">
                                        <div className="collapse-footer-menu">
                                            <ul id="menu-header-menu-1" className="collapse-footer-menu-inner">
                                                <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-515">
                                                    <Link to="/about-us" onClick={toggleMenu}><span>About Us</span></Link>
                                                </li>
                                                <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-43854">
                                                    <Link to="/our-terms" onClick={toggleMenu}><span>Terms of Use</span></Link>
                                                </li>
                                                <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-43168">
                                                    <Link to="/dmca-policy" onClick={toggleMenu}><span>DMCA Policy</span></Link>
                                                </li>
                                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-49848">
                                                    <Link to="/privacy-policy" onClick={toggleMenu}><span>Privacy Policy</span></Link>
                                                </li>
                                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-49848">
                                                    <Link to="/contact-us" onClick={toggleMenu}><span>Contact Us</span></Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="collapse-copyright">© Inspiring Leaders || All Rights Reserved</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!isMobileHideClass && (
                <a href="#top" id={containerID} aria-label="back to top" className={isVisible ? 'scroll-btn-visible' : ''} onClick={scrollToTop}><i class="rbi rbi-darrow-top"></i></a>
            )}
        </div>
    );
};

function navbarcategory(catId, catName,groupedPosts, key) {
    const catnameurl = catName.toLowerCase().replace(/\s+/g, '-');
    const idkey = "mega-dropdown-" + catId;

  // Get posts for this category
  const categoryPosts = groupedPosts.find(group => group.category === key)?.posts || [];

  return (
    <li id="menu-item-515" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-515 menu-item-has-children menu-has-child-mega is-child-wide">
      <Link title={`Go to the ${catName} Biography Category archives.`} 
            to={{
              pathname: `/${catnameurl}`,
              state: { catId: catId }
            }}>
        <span>{catName}</span>
      </Link>
      <div className="mega-dropdown is-mega-category" id={idkey}>
        <div className="rb-container">
          <div className="mega-dropdown-inner">
            <div className="mega-header mega-header-fw">
              <span className="h4">{catName}</span>
              <Link className="mega-link is-meta" 
                    to={{
                      pathname: `/${catnameurl}`,
                      state: { catId: catId }
                    }}>
                <span>Show More</span><i className="rbi rbi-cright" aria-hidden="true"></i>
              </Link>
            </div>
            <div id="mega-listing-515" className="block-wrap block-small block-grid block-grid-small-1 rb-columns rb-col-5 is-gap-10 meta-s-">
              <div className="block-inner">
                {/* Check if there are posts for the category */}
                {categoryPosts.length > 0 ? (
                  categoryPosts.map((post) => (
                    <div className="p-wrap p-grid p-grid-small-1" key={post.id} data-pid={post.id}>
                      <div className="feat-holder overlay-text">
                        <div className="p-featured"> 
                          <Link className="p-flink" 
                                to={{
                                  pathname: `/${catnameurl}/${post.titleurl}/${catId}/${post.id}`,
                                  state: {
                                    catsurl: catnameurl,
                                    posttitleurl: post.titleurl,
                                    id: post.id,
                                    biocat: catId,
                                  },
                                }} 
                                title={post.title}> 
                            <img loading="lazy" width="330" height="220" src={`${SERVER_IMG_PATH}/${post.featuredImage}`} className="featured-img wp-post-image" alt={post.title} decoding="async" srcSet={`${SERVER_IMG_PATH}/${post.featuredImage} 615`} sizes="(max-width: 330px) 100vw, 330px" /> 
                          </Link> 
                        </div>
                      </div>
                      <div className="p-content"> 
                        <span className="entry-title h4"> 
                          <Link className="p-url" 
                                to={{
                                  pathname: `/${catnameurl}/${post.titleurl}/${catId}/${post.id}`,
                                  state: {
                                    catsurl: catnameurl,
                                    posttitleurl: post.titleurl,
                                    id: post.id,
                                    biocat: catId,
                                  },
                                }}
                                rel="bookmark">
                            {post.title}
                          </Link>
                        </span>
                        <div className="p-meta">
                          <div className="meta-inner is-meta"> 
                            <span className="meta-el meta-update">
                              <MenuTime publicationDate={post.publicationDate} /> 
                            </span> 
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{width: '100%'}}>No insights are available for this category.</div> // Message when there are no posts
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

function menubiolink(catId, catName) {
    const catnameurl = catName.toLowerCase().replace(/\s+/g, '-');
    return (
            <li id="menu-item-667" className="menu-item menu-item-type-post_type menu-item-object-page">
            <Link to={{
                    pathname: `/${catnameurl}`,
                    state: { catId: catId }
                }} >{catName}</Link> </li>
    );
}

export default Header;