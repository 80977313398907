import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/dmca-Policy-page.css';

const ContactUs = ( ) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    
    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []); // Empty dependency array means this runs once on mount
    
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // emailjs.send(
        //   'YOUR_SERVICE_ID',    // Replace with your EmailJS service ID
        //   'YOUR_TEMPLATE_ID',   // Replace with your EmailJS template ID
        //   formData,
        //   'YOUR_USER_ID'        // Replace with your EmailJS user ID
        // ).then((result) => {
        //   alert('Message sent successfully!');
        // }, (error) => {
        //   console.error('Error:', error.text);
        //   alert('Failed to send message. Please try again later.');
        // });
        toast('Message sent successfully!', {
            type:'sucess',
            autoClose: 3000
        });
    
        // Clear the form
        setFormData({
          name: '',
          email: '',
          message: '',
        });
    };

  return (
        <div className="site-wrap">
            <div className="single-page without-sidebar sticky-sidebar">
                <header className="page-header page-header-1 edge-padding rb-container">
                    <h1 className="s-title">Contact Us</h1>
                </header>
                <div className="rb-small-container edge-padding">
                    <div className="grid-container">
                        <div className="s-ct">
                            <div className="s-ct-inner">
                                <div className="e-ct-outer">
                                    <div className="entry-content rbct">
                                        <h2><strong>Contact Us for Inspiring Leaders</strong></h2>
                                        <p>We value your feedback, questions, and suggestions. Whether you have a query about our content, want to report an issue, or simply wish to get in touch, we’re here to help.</p>
                                        <p><span style={{color: '#ffaa12'}}><strong>General Inquiries:</strong></span><br/> If you have any general questions or comments, please feel free to reach out to us at:<br/><strong>Mail : </strong><em><b>info@inspiringleaders.ai.</b></em></p>
                                        <p><span style={{color: '#ffaa12'}}><strong>Corrections and Updates:</strong></span><br/> At Inspiring Leaders, we strive to provide accurate and up-to-date information. If you notice any errors or outdated information on our site, kindly let us know, and we will make the necessary corrections as soon as possible.<br/><strong>Mail : </strong><em><b>info@inspiringleaders.ai.</b></em></p>
                                        <p><span style={{color: '#ffaa12'}}><strong>Advertising and Partnerships:</strong></span><br/>For advertising inquiries, partnerships, or collaborations, please contact us at:<br/><strong>Mail : </strong><em><b>info@inspiringleaders.ai.</b></em></p>
                                        <p>We aim to respond to all inquiries within 24-48 hours. Thank you for visiting Inspiring Leaders, and we look forward to hearing from you!</p>
                                        <div style={formContainer}>
                                            <form onSubmit={handleSubmit} style={formStyle}>
                                                <h2>Contact Us</h2>
                                                <div style={inputContainer}>
                                                <label style={labelStyle}>Name:</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                    style={inputStyle}
                                                />
                                                </div>
                                                <div style={inputContainer}>
                                                <label style={labelStyle}>Email:</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                    style={inputStyle}
                                                />
                                                </div>
                                                <div style={inputContainer}>
                                                <label style={labelStyle}>Message:</label>
                                                <textarea
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                    style={textareaStyle}
                                                />
                                                </div>
                                                <button type="submit">Send</button>
                                            </form>
                                        </div>
                                        <ToastContainer />
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};

// Simple inline styles
const formContainer = {
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
    padding: '20px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    background: '#fff',
};
  
const formStyle = {
    display: 'flex',
    flexDirection: 'column',
};
  
const inputContainer = {
    marginBottom: '15px',
};
  
const labelStyle = {
    marginBottom: '5px',
    fontWeight: 'bold',
};
  
const inputStyle = {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '100%',
    fontSize: '16px',
};
  
const textareaStyle = {
    ...inputStyle,
    height: '100px',
};
  
const buttonStyle = {
    padding: '10px 15px',
    background: '#ff0070',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
};

export default ContactUs;
