import React from 'react';
import { useEffect, useRef, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import {useSelector} from "react-redux"
import axios from "axios";
// import { useDispatch } from "react-redux";
import { AuthContext } from "../context/authContext";
import SunEditor from "suneditor-react";
import { SERVER_PATH, SERVER_IMG_PATH } from "../AppConstants";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/write-post.css';

const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana"
  ];

const MIN_WIDTH = 800;
const MIN_HEIGHT = 513;

const Write = () => {
    const state = useLocation().state;
    const [title, setTitle] = useState(state?.title || "");
    const [dbid, setDbid] = useState(state?.id || '');
    const [generatedTitle, setGeneratedTitle] = useState(state?.titleurl || "");
    const [biocat, setBiocat] = useState(state?.category || "0");
    const [catsSelectedLabel, setCatsSelectedLabel] = useState('');
    const [dbfeaturedimage, setDbfeaturedimage] = useState(state?.featuredImage || "");
    const [featuredimage, setFeaturedimage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [blogcontent, setBlogcontent] = React.useState(state?.content || "");
    const [err, setErr] = useState(null);
    const [isDisable, setIsDisable] = useState(false);
    const cats = useSelector((state)=> state.catsInfo.cats);
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    if(!currentUser) {
        navigate("/");
    }
    // This code used for add category in dynamic
    // const dispatch = useDispatch();
    // const [formCat, setFormCat] = useState({0:"", 1: "", 2: "", 3: "", 4: "", 5: "", 6: "",
    // });
    // const addCat = () => {
    //     dispatch(setCats (formCat));
    // };
    useEffect(() => {
        if(state?.featuredImage) {
            setDbfeaturedimage(state.featuredImage);
            setImagePreviewUrl(SERVER_IMG_PATH + "/" +state.featuredImage)
        }
    }, [state?.featuredImage]); // This effect will run only when post.title changes
    //console.log(biocat);
    //console.log(cats);
    useEffect(() => {
        Object.keys(cats).forEach((key) => {
            //console.log(cats[key][1]);
          if (key == biocat) {
            setCatsSelectedLabel(cats[biocat][1]);
            //console.log(cats[biocat][1]);
          }
        });
      }, [cats, biocat, setCatsSelectedLabel]); 
    const handletitleChange=(e)=>{
        const titlecon = e.target.value.toLowerCase().replace(/\s+/g, '-');
        setTitle(e.target.value);
        setGeneratedTitle(titlecon + "-" + Date.now());
    };
    const onChangeCats = (event) => {
        const value = event.target.value;
        const selectedText = event.target.options[event.target.selectedIndex].text;
        setCatsSelectedLabel(selectedText);
        setBiocat(value);
      };
    const handleChangeImage = (e)=>{
        // console.log(e.target.files);
        const file = e.target.files[0];
        if (file.size > 5 * 1024 * 1024) { // 5MB size limit
            toast('Featured Image size must be less than 5MB!', {
                type:'error',
                autoClose: 3000
              });
              return;
          } else {
            // Create a FileReader to read the file
            const reader = new FileReader();

            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    const { width, height } = img;
                    
                    // Check dimensions
                    if (width < MIN_WIDTH || height < MIN_HEIGHT) {
                        toast('Featured image dimensions must be at least 800 x 513', {
                            type:'error',
                            autoClose: 3000
                        });
                        return;
                    } else {
                        setFeaturedimage(file);
                        setImagePreviewUrl(reader.result);
                    }
                };
            };
            reader.readAsDataURL(file);
          }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsDisable(true);
        let hasError = false; 

        if(title.trim() === ''){
            hasError = true;
            toast('Enter a title', {
              type:'error',
              autoClose: 3000
            });
            setIsDisable(false);
            return;
        } else if (title.trim().length < 20) {
            hasError = true;
            toast('Title must be at least 20 characters long!', {
                type:'error',
                autoClose: 3000
            });
            setIsDisable(false);
            return;
        } else if (title.trim().length > 70) {
            hasError = true;
            toast('Title is more than 70 characters long!', {
                type:'error',
                autoClose: 3000
            });
            setIsDisable(false);
            return;
        } else if (biocat === "0") {
            hasError = true;
            toast('You must select an Category!', {
                type:'error',
                autoClose: 3000
            });
            setIsDisable(false);
            return;
        } 
        if(state && state.title) {
            if (!featuredimage) {
                hasError = true;
                toast('You must select an Featured Image!', {
                    type:'error',
                    autoClose: 3000
                });
                setIsDisable(false);
                return;
            }
        }

        if (!hasError) {
            try {
                if(state) {
                    const formData = new FormData();
                    formData.append("title", title);
                    formData.append("biocat", biocat);
                    formData.append("titleurl", generatedTitle);
                    if (featuredimage) {
                        formData.append("featuredimageFile", featuredimage);
                    } else {
                        formData.append("imageUrl", dbfeaturedimage);
                    }
                    formData.append("blogcontent", blogcontent);
                    formData.append("date", moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
                    const response = await axios.put(SERVER_PATH + '/posts/' + dbid, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                      withCredentials: true, // This ensures cookies are sent with the request
                    });
                    const changeurl = catsSelectedLabel.toLowerCase().replace(/\s+/g, '-');
                    const id = dbid;
                    console.log(response.data);
                    console.log(id);
                    navigate(`/${changeurl}/${generatedTitle}/${biocat}/${id}`,{
                            state: {
                            catsurl: changeurl,
                            posttitleurl: generatedTitle,
                            id: id,
                            biocat: biocat,
                        },
                    });
                    setIsDisable(false);
                } else {
                    const formData = new FormData();
                    formData.append("title", title);
                    formData.append("biocat", biocat);
                    formData.append("titleurl", generatedTitle);
                    formData.append("featuredimageFile", featuredimage);
                    formData.append("blogcontent", blogcontent);
                    formData.append("date", moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"));
                    const response = await axios.post(SERVER_PATH + '/posts', formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                      withCredentials: true, // This ensures cookies are sent with the request
                    });
                    const changeurl = catsSelectedLabel.toLowerCase().replace(/\s+/g, '-');
                    const id = response.data.id;
                    
                    toast('Error Updateing data into database!', {
                        type:'sucess',
                        autoClose: 3000
                    });
                    navigate(`/${changeurl}/${generatedTitle}/${biocat}/${id}`,{
                            state: {
                            catsurl: changeurl,
                            posttitleurl: generatedTitle,
                            id: id,
                            biocat: biocat,
                        },
                    });
                    setIsDisable(false);
                }
              } catch (error) {
                //console.log(error.response.data);
                toast('Error Updateing data into database!' + error.response.data, {
                    type:'error',
                    autoClose: 3000
                  });
                setIsDisable(false);
              }
        }
        setErr(err);
        setIsDisable(false);
    }
    const sortedFontOptions = [
        "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto",
          "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif",
        "Logical",
        "Salesforce Sans",
        "Garamond",
        "Sans-Serif",
        "Serif",
        "Times New Roman",
        "Helvetica",
        ...defaultFonts
      ].sort();
    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        fileInputRef.current.click();
      };


    return (
        <div>
            <header className="search-header">
                <div className="rb-container edge-padding">
                    <div className="search-header-inner">
                        <div className="search-header-content">
                            <h1 className="search-title"> Biography </h1>
                            {/* <p className="search-subtitle">Showing 3817 results for your search</p> */}
                        </div>
                        <div className="search-header-form">
                            <div role="search" method="get" className="search-form wp-block-search">
                                <div className="search-form-icon"><span aria-hidden="true"><i>Compose a Biography</i></span></div>
                            </div> 
                        </div>
                    </div>
                </div>
            </header>
            <div className="blog-builder">
                <div data-elementor-type="wp-post" data-elementor-id="50417" className="elementor elementor-50417">
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-117d2b4 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="117d2b4" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shape_divider_bottom&quot;:&quot;waves&quot;}">
                        <div className="elementor-container elementor-column-gap-custom">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9c019a1" data-id="9c019a1" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form onSubmit={handleSubmit} method="post">
                                                <h1> Biography </h1>
                                                <fieldset>
                                                    <legend> Biography Info</legend> <label for="name">Title:</label> 
                                                    <input type="text" id="title" name="biotitle" value={title} onChange={handletitleChange} placeholder='Enter the title of the biography' /> 
                                                </fieldset>
                                                <fieldset> 
                                                    <label for="cat">Category:</label>
                                                    <select id="cat" name="biocat" value={biocat} onChange={onChangeCats}>
                                                    {
                                                        Object.keys(cats).map((key, i) => (
                                                            <option value={i}>{cats[key][1]} </option>
                                                        ))
                                                    }
                                                    </select> 
                                                </fieldset>
                                                <fieldset>
                                                    <label for="name">Featured Image:</label> 
                                                    <input type="file" name="biofeaturedimageFile" accept="image/png , image/jpeg, image/webp" multiple={false} ref={fileInputRef} onChange={handleChangeImage} />
                                                </fieldset>
                                                <fieldset>
                                                    <img className="coverphoto w-full h-full border-2 border-[#f6cd66]" src={imagePreviewUrl} onClick={handleImageClick} />
                                                </fieldset>
                                                <fieldset>
                                                    <label >Biography Article:</label> 
                                                    <SunEditor id="noselect" name="biocontent" className="sunditorheader" 
                                                        setDefaultStyle="font-family: system-ui; font-size: 20px;"
                                                        setContents={blogcontent}
                                                        onChange={setBlogcontent}
                                                        setOptions={{
                                                        buttonList: [
                                                            ["undo", "redo"],
                                                            ["font", "fontSize",'formatBlock'],
                                                            ['paragraphStyle', 'blockquote'],
                                                            ["bold", "underline", "italic", "strike", "subscript","superscript"],
                                                            ["fontColor", "hiliteColor"],
                                                            ["align", "list", "lineHeight"],
                                                            ["outdent", "indent"],

                                                            ["table", "horizontalRule", "link", "image", "video"],
                                                            //['math'], //You must add the 'katex' library at options to use the 'math' plugin.
                                                            // ['imageGallery'], // You must add the "imageGalleryUrl".
                                                            ["fullScreen", "showBlocks"],
                                                            ["preview", "print"],
                                                            ["removeFormat"],
                                                            ['save'],
                                                            // ['save', 'template'],
                                                            // ['/'], //Line break
                                                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                                                        defaultTag: "div",
                                                        minHeight: "500px",
                                                        mode: 'balloon-always',
                                                        showPathLabel: false,
                                                        font: sortedFontOptions
                                                        }}
                                                    />
                                                </fieldset>
                                                <fieldset>
                                                    <ToastContainer />
                                                </fieldset>
                                                <input
                                                className="biographybtn"
                                                type="submit"
                                                disabled={isDisable}
                                                value={`${state ? "Update Biography" : "Create Biography"}`}
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Write;