import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContexProvider } from "./context/authContext";
import {Provider} from "react-redux";
import store from "./redux/store.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store = {store} >
    <AuthContexProvider>
      <App />
    </AuthContexProvider>
  </Provider>
  // <React.StrictMode>
  //   <Provider store = {store} >
  //     <AuthContexProvider>
  //       <App />
  //     </AuthContexProvider>
  //   </Provider>
  // </React.StrictMode>
);
