import React, { useEffect } from "react";
import '../assets/css/dmca-Policy-page.css';

const AboutUs = ( ) => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []); // Empty dependency array means this runs once on mount

  return (
        <div class="site-wrap">
          <div class="single-page without-sidebar sticky-sidebar">
              <header class="page-header page-header-1 edge-padding rb-container">
                  <h1 class="s-title">About Us</h1>
              </header>
              <div class="rb-small-container edge-padding">
                  <div class="grid-container">
                      <div class="s-ct">
                          <div class="s-ct-inner">
                              <div class="e-ct-outer">
                                  <div class="entry-content rbct">
                                      <p>Inspiring Leaders is dedicated to providing authentic and up-to-date information about trending celebrities and notable personalities from around the globe.</p>
                                      <p>Our content is meticulously curated from various sources, including the internet, books, newspapers, and other reliable resources. While we strive for accuracy in all our content, we acknowledge that occasional errors may occur. If you come across any inaccuracies, please feel free to reach out to us, and we will promptly make corrections.</p>
                                      <p>Please note that while we make every effort to ensure the information is accurate,<strong> we cannot guarantee that everything is 100% correct</strong>.</p>
                                      <p>For any inquiries or corrections, please contact us at&nbsp;<em><b>info@inspiringleaders.ai.</b></em></p>
                                      <div class="clearfix"></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default AboutUs;
