import { useState } from 'react'
import '../assets/css/admin-style.css';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function RegisterPage(){
    const initialStateErrors = {
        email:{required:false},
        password:{required:false},
        name:{required:false},
        custom_error:null
    };
    const [errors,setErrors] = useState(initialStateErrors);
    const [err, setErr] = useState(null);

    const [loading,setLoading]  =  useState(false);

    const [inputs,setInputs] = useState({
        email:"",
        password:"",
        name:""
    })
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors =initialStateErrors; 
        let hasError = false; 
        if (inputs.name == "") {
            errors.name.required =true;
            hasError=true;
        }
        if (inputs.email == "") {
            errors.email.required =true;
            hasError=true;
        }
        if (inputs.password == "") {
            errors.password.required =true;
            hasError=true;
        }

        if (!hasError) {
            setLoading(true)
            //sending register api request
            try {
                await axios.post("http://localhost:8800/client/auth/register", inputs);
                navigate("/administrator");
            } catch (err) {
                setErr(err.response.data);
            //     if(err.response.data.error.message=="EMAIL_EXISTS"){
            //         setErrors({...errors,custom_error:"Already this email has been registered!"})
            //    }else if(String(err.response.data.error.message).includes('WEAK_PASSWORD')){
            //         setErrors({...errors,custom_error:"Password should be at least 6 characters!"})
            //    }
            } finally {
                setLoading(false)
            }
            
        }
        console.log(initialStateErrors,errors);
        setErrors(errors);
    }

    const handleInput = (event)=>{
        setInputs({...inputs,[event.target.name]:event.target.value})
    }

    // if (isAuthenticated()) {
    //     //redirect user to dashboard
    //     return <Navigate to="/dashboard" />
    // }
    
    return (
        <div>
            <div class="container">
                <div class="wrapper">
                    <div class="title"><span>Register Now</span></div>
                        <form onSubmit={handleSubmit} className="register-form" action="" >
                        <div class="row">
                                <i class="fas fa-user"></i>
            
                            <input type="text" className="form-control" placeholder="User Name" onChange={handleInput} name="name" id=""  />
                        { errors.name.required?
                            (<span className="text-danger" >
                                    Name is required.
                                </span>):null
                            }
                        </div>
                        <div class="row">
                                <i class="fas fa-email"></i>
            
                            <input type="text"  className="form-control" placeholder="Email" onChange={handleInput} name="email" id=""  />
                            { errors.email.required?
                            (<span className="text-danger" >
                                Email is required.
                            </span>):null
                            }
                        </div>
                        <div class="row">
                                <i class="fas fa-user"></i>
                            <input  className="form-control" type="password" placeholder="Password" onChange={handleInput}  name="password" id="" />
                            { errors.password.required?
                            (<span className="text-danger" >
                                Password is required.
                            </span>):null
                            }
                        </div>
                        <div className="form-group">
            
                            <span className="text-danger" >
                            {err && <p>{err}</p>}
                            </span>
                            {loading ?
                            (<div  className="text-center">
                                <div className="spinner-border text-primary " role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>):null
                            }
            
                            <input type="submit" className="btn btn-login float-right" disabled={loading}  value="Register" />
                        </div>
                        <div className="clearfix"></div>
                        <div class="signup-link">Not a member? <Link to="/administrator">Signin</Link></div>
                    </form>
                </div>
            </div>
        </div>
    )
}