import axios from "axios";
import '../assets/css/admin-style.css';
import React, { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/authContext";

export default function LoginPage (){
    const initialStateErrors = {
        email:{required:false},
        password:{required:false},
        custom_error:null
    };
    const [errors,setErrors] = useState(initialStateErrors);
    const [err, setErr] = useState(null);
    
    const [loading,setLoading]  =  useState(false);

    const [inputs,setInputs] = useState({
        email:"",
        password:"",
    })

    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    
    const handleInput = (event)=>{
        setInputs({...inputs,[event.target.name]:event.target.value})
    }

    const handleSubmit = async (e) => {
        //console.log(inputs);
        e.preventDefault();
        let errors =initialStateErrors; 
        let hasError = false; 
        
        if (inputs.email == "") {
            errors.email.required =true;
            hasError=true;
        }
        if (inputs.password == "") {
            errors.password.required =true;
            hasError=true;
        }
       
        if (!hasError) {
            setLoading(true)
            //sending login api request
            try {
                await login(inputs);
                navigate("/");
                window.location.reload();
              } catch (err) {
                // setErrors({...errors,custom_error:"Invalid Credentials."})
                // setErrors({...errors,custom_error:err.response.data})
                setErr(err.response);
              } finally {
                setLoading(false)
            }
            
        }
        setErrors({...errors});

    }


    return (
        <div>
            <div class="container">
                <div class="wrapper">
                    <div class="title"><span>Login Form</span></div>
                        <form onSubmit={handleSubmit} className="login-form" action="">
                            <div class="row">
                                <i class="fas fa-user"></i>
                                <input type="email"  className="form-control" onChange={handleInput} name="email"  id="email" placeholder="Email"  />
                                { errors.email.required?
                                (<span className="text-danger" >
                                    Email is required.
                                </span>):null
                                }
                            </div>
                            <div class="row">
                                <i class="fas fa-lock"></i>
                                <input  className="form-control" type="password" onChange={handleInput}  name="password" placeholder="Password" id="password" />
                                { errors.password.required?
                                (<span className="text-danger" >
                                    Password is required.
                                </span>):null
                                }
                            </div>
                            <div className="form-group">
                                {loading ?
                                (<div  className="text-center">
                                    <div className="spinner-border text-primary " role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>):null
                                }
                                <span className="text-danger" >
                                {err && <p>{err}</p>}
                                { errors.custom_error?
                                (<p>{errors.custom_error}</p>)
                                :null
                                }
                                </span>
                                <div class="row button">
                                    <input  type="submit" className="btn btn-login float-right" disabled={loading}  value="Login" />
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="form-group">
                            {/* <div class="signup-link">Not a member? <Link  to="/register">Signup now</Link></div> */}
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
    )
}