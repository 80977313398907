import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import RegisterPage from "./admin/RegisterPage";
import LoginPage from "./admin/LoginPage";
import Write from "./pages/write";
import IndexContant from "./pages/indexcontant";
import CategoryContant from "./pages/CategoryContant";
import SinglePost from "./pages/singlepost";
import Header from "./layout/header";
import Footer from "./layout/footer";
import PageNotFound from './pages/404';
import AboutUs from './pages/AboutUs';
import OurTerms from './pages/OurTerms';
import DMCAPolicy from './pages/DMCAPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactUs from './pages/ContactUs';

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <IndexContant />,
      },
      {
        path: "/:catsurl",
        element: <CategoryContant />,
      },
      {
        path: "/:catsurl/:posturl/:biocat/:id",
        element: <SinglePost />,
      },
      // {
      //   path: "/write",
      //   element: <Write />,
      // },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/our-terms",
        element: <OurTerms />,
      },
      {
        path: "/dmca-policy",
        element: <DMCAPolicy />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/administrator",
    element: <LoginPage />,
  },
]);


function App() {
  return (
    <div className="app">
        <RouterProvider router={router} />
    </div>
  );
}
//administrator
export default App;
