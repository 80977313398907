import React, { useState, useEffect } from 'react';
import '../assets/css/dmca-Policy-page.css';

const OurTerms = ( ) => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []); // Empty dependency array means this runs once on mount
  return (
        <div className="site-wrap">
          <div className="single-page without-sidebar sticky-sidebar">
              <header className="page-header page-header-1 edge-padding rb-container">
                  <h1 className="s-title">Terms and Conditions</h1>
              </header>
              <div className="rb-small-container edge-padding">
                  <div className="grid-container">
                      <div className="s-ct">
                          <div className="s-ct-inner">
                              <div className="e-ct-outer">
                                  <div className="entry-content rbct">
                                      <h2><strong>Terms and Conditions for Inspiring Leaders</strong></h2>
                                      <p>Welcome to Inspiring Leaders. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>
                                      <p><span style={{color: '#ffaa12'}}><strong>Acceptance of Terms</strong></span><br/> By using Inspiring Leaders, you acknowledge that you have read, understood, and agree to these Terms and Conditions. If you do not agree to these terms, please do not use our website.</p>
                                      <p><span style={{color: '#ffaa12'}}><strong>Content</strong></span><br/>All content provided on Inspiring Leaders is for informational purposes only. We strive to provide accurate and up-to-date information about celebrities and notable personalities, but we make no guarantees regarding the accuracy, completeness, or reliability of the information presented.</p>
                                      <p><span style={{color: '#ffaa12'}}><strong>Intellectual Property</strong></span><br/>The content on Inspiring Leaders, including but not limited to text, images, videos, and graphics, is the property of Inspiring Leaders or our content providers and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, or use any content without our express written consent.</p>
                                      <p><span style={{color: '#ffaa12'}}><strong>User Conduct</strong></span><br/>By using our website, you agree not to:</p>
                                      <ul>
                                        <li>Use our content for any unlawful purpose.</li>
                                        <li>Disrupt or interfere with the security or performance of our website.</li>
                                        <li>Attempt to gain unauthorized access to any part of the website.</li>
                                      </ul>
                                      <p><span style={{color: '#ffaa12'}}><strong>External Links</strong></span><br/>Inspiring Leaders may contain links to external websites that are not operated by us. We have no control over the content and practices of these sites and cannot accept responsibility for their terms and conditions or privacy policies.</p>
                                      <p><span style={{color: '#ffaa12'}}><strong>Disclaimer</strong></span><br/>Inspiring Leaders is provided "as is" without any warranties, express or implied. We do not guarantee that our website will be available at all times or that it will be free of errors, viruses, or other harmful components.</p>
                                      <p><span style={{color: '#ffaa12'}}><strong>Limitation of Liability</strong></span><br/>Inspiring Leaders shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our website, including but not limited to mistakes, omissions, interruptions, or delays in service.</p>
                                      <p><span style={{color: '#ffaa12'}}><strong>Changes to Terms and Conditions</strong></span><br/>We reserve the right to modify these Terms and Conditions at any time. Any changes will be posted on this page, and it is your responsibility to review them regularly. Your continued use of Inspiring Leaders after changes have been posted constitutes your acceptance of the modified terms.</p>
                                      <p><span style={{color: '#ffaa12'}}><strong>Governing Law</strong></span><br/>These Terms and Conditions are governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising out of or related to these terms will be subject to the exclusive jurisdiction of the courts in new delhi, india.</p>
                                      <p><span style={{color: '#ffaa12'}}><strong>Contact Information</strong></span><br/>If you have any questions or concerns about these Terms and Conditions, please contact us at <strong>info@inspiringleaders.ai</strong> .</p>
                                      <div className="clearfix"></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default OurTerms;
