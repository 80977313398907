import React, { useEffect } from "react";
import { Link } from "react-router-dom";


const PageNotFound = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []); // Empty dependency array means this runs once on mount
    return(
        <div class="site-wrap"> 
            <div class="page404-wrap rb-container edge-padding">
                <div class="page404-inner">
                    <div class="page404-featured"></div>
                    <h1 class="page-title">Something's wrong here...</h1>
                    <p class="page404-description">It looks like nothing was found at this location. The page you were looking for does not exist or was loading incorrectly.</p>
                    <div class="page404-btn-wrap">
                        <Link to="/" class="page404-btn is-btn">Return to Homepage</Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export  default PageNotFound;